.product-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: var(--brand-color); */
}

.product-group h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 30px;
  color: var(--brand-color);
}

.product-group .product-form-group,
.group-items-table {
  width: 90%;
}

/* .product-group .image-upload-section {
  max-width: 90%;
} */

.product-group .product-form-group span {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
  line-height: 15px;
  color: #2d9cdb;
}

.product-group .form-field {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  justify-content: space-between;
}

.product-group .form-field > img {
  position: absolute;
  top: 60%;
  cursor: pointer;
  right: -30px;
}

.product-group .form-field > div {
  width: 49%;
}

.product-group .form-field.single-field > div {
  width: 100%;
}

.product-group .form-field > div label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--brand-color);
  margin-bottom: 12px;
}

.product-group .form-field > div input,
.product-group .form-field > div textarea {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  padding: 12px;
  line-height: 24px;
  background: transparent;
  border: 1px solid var(--brand-color);
  outline: none;
  color: var(--brand-color);
}

.product-group .form-field > div .options {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  padding: 14px 6px;
  line-height: 24px;
  background: transparent;
  border: 1px solid var(--brand-color);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 6px; */
  color: var(--brand-color);
}

.product-group .form-field > div .options ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
  width: fit-content;
}

.product-group .form-field > div .options input {
  width: fit-content;
  border-radius: 0;
  padding: 0;
  margin: 0;
  padding: 1px;
  border: none;
}

.product-group .form-field > div .options ul li {
  width: max-content;
}

.product-group .form-field > div .options ul li span {
  background: #bdbdbd;
  color: #767676;
  display: flex;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 3px;
  padding: 2px 8px;
}

.product-group .form-field > div .options ul li span img {
  padding-left: 10px;
}

.check_unique {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 10px;
  height: 20px;
}

.check_unique > div {
  /* width: max-content; */
  display: flex;
  align-items: center;
}

.check_unique input:checked {
  background: var(--brand-color);
}

.check_unique > div p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.check_unique .container {
  /* display: block; */
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check_unique .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eaeaea;
}

/* On mouse-over, add a grey background color */
.check_unique .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check_unique .container input:checked ~ .checkmark {
  background-color: var(--brand-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_unique .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_unique .container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* item group part individual product*/
.group-items-table {
  margin-top: 80px;
}

.group-items-table table {
  width: 100%;
}

.group-items-table table tr {
  border: none;
  position: relative;
}

.group-items-table table thead tr th {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 46px;
  text-align: left;
  color: var(--brand-color);
}

.group-items-table table thead tr th p {
  color: #2d9cdb;
  cursor: pointer;
}

.group-items-table table tbody tr td input {
  width: 94%;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  padding: 12px;
  line-height: 24px;
  background: transparent;
  border: 1px solid var(--brand-color);
  outline: none;
  color: var(--brand-color);
}

.group-items-table table tbody tr td.group-item-name {
  width: 340px;
}

.group-items-table table tbody tr td.group-item-name > textarea,
.group-items-table table tbody tr td > textarea {
  width: 94%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  cursor: text;
  height: auto;
  border-radius: 12px;
  padding: 12px;
  line-height: 24px;
  background: transparent;
  border: 1px solid var(--brand-color);
  outline: none;
  color: var(--brand-color);
}

.group-items-table table tbody tr td.group-item-name > div span {
  width: fit-content;
}

.group-items-table table tbody tr td.group-item-name > div input {
  padding: 0;
  width: 1px;
  /* padding-right: 10px; */
  border: none;
  border-radius: 0;
}

.group-items-table table tbody {
  width: 100%;
}

.group-items-table table tbody p {
  font-style: italic;
}

.group-items-table table tr .delete-combinations {
  position: absolute;
  bottom: 40%;
  cursor: pointer;
  color: var(--brand-color);
}

.submit-product-group {
  margin: 100px;
  display: flex;
  justify-content: center;
}

.submit-product-group button {
  /* margin: 100px; */
  padding: 20px 200px;
  border: none;
  background-color: var(--brand-color);
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
}

.product-group .product_group_instructions {
  width: 100%;
  display: flex;
  justify-content: right;
}
.product-group .product_group_instructions > ul {
  list-style-type: square;
  padding: 0;
  margin: 0;
  width: 48%;
}

.product-group .product_group_instructions > ul li ul {
  list-style-type: square;
  margin-left: 102px;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 430px) {
  .product-group .product-form-group,
  .group-items-table {
    width: 95%;
  }

  .group-items-table {
    overflow-x: scroll;
  }

  .product-group h1 {
    font-size: 12px;
    line-height: 18px;
  }

  .product-group .form-field {
    display: block;
  }

  .product-group .image-upload-section {
    max-width: 95%;
  }

  .product-group .form-field.product_group_attributes {
    display: flex;
  }

  .product-group .form-field.product_group_attributes > div {
    width: 45%;
  }

  .product-group .form-field.product_group_attributes > img {
    position: static;
    top: 60%;
    cursor: pointer;
    right: -30px;
  }

  .product-group .form-field > div {
    width: 100%;
    margin-bottom: 0;
  }

  .product-group .form-field > div label {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 8px;
  }

  .product-group .form-field > div input,
  .product-group .form-field > div textarea {
    font-size: 10px;
    line-height: 15px;
    border-radius: 6px;
  }

  .group-items-table table thead tr th {
    font-size: 10px;
    line-height: 15px;
    padding-bottom: 20px;
  }

  .group-items-table table thead tr th p,
  tr td p {
    font-size: 10px;
    line-height: 15px;
  }

  .group-items-table .individual_item_group {
    margin-bottom: 12px;
  }

  .group-items-table table tbody tr td.group-item-name {
    width: unset;
  }

  .group-items-table table tr .delete-combinations {
    position: static;
    margin-top: 150%;
  }

  .group-items-table table tbody tr td input {
    width: 100px;
    font-size: 10px;
    margin-right: 10px;
    border-radius: 6px;
    padding: 7px 6px;
    line-height: 15px;
  }

  .group-items-table table tbody tr td.group-item-name > textarea,
  .group-items-table table tbody tr td > textarea {
    width: 100px;
    margin-right: 10px;
    font-size: 10px;
    cursor: text;
    height: auto;
    border-radius: 6px;
    padding: 7px 6px;
    line-height: 15px;
  }

  .product-group .form-field > div .options {
    font-size: 10px;
    border-radius: 6px;
    padding: 14px 6px;
    line-height: 15px;
  }

  .product-group .form-field > div .options ul {
    gap: 4px;
  }

  .product-group .form-field > div .options ul li span {
    font-size: 8px;
    line-height: 12px;
    border-radius: 3px;
    padding: 4;
    display: flex;
    align-items: center;
  }

  .product-group .form-field > div .options ul li span img {
    padding-left: 6px;
    height: 4px;
  }

  .submit-product-group {
    margin: 50px 0;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .submit-product-group button {
    padding: 15px 0;
    width: 95%;
    font-size: 12px;
    line-height: 18px;
    border-radius: 6px;
  }

  .product-group .product_group_instructions > ul {
    list-style-type: square;
    padding: 0;
    margin: 0;
    width: 80%;
  }
}
