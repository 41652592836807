.product-list {
  color: #333;
}

.product-list .table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;
}

.product-list .table > h2 {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 0;
  color: var(--brand-color);
}

.product-list .table .search {
  width: 100%;
  max-width: 300px;
}

.product-list .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  position: relative;
}

.product-list .table table tr {
  height: 50px; /* Set a consistent height for table rows */
}

.product-list .table table th {
  padding: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  background-color: #fff;
  color: var(--brand-color);
  border: none;
}

.product-list .table table td {
  padding: 16px 0 16px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #5a5a5a;
}

.product-list .table table td img.product_img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.product-list .table table th,
.product-list .table table td {
  vertical-align: middle;
  text-align: center;
}

.product-list .table table th.icons,
.product-list .table table td.icons {
  padding: 0;
  margin: auto;
}

.product-list .table table th.icons > div,
.product-list .table table td.icons > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-list .table table th.icons > div > *,
.product-list .table table td.icons > div > * {
  margin-right: 7px;
  cursor: pointer;
}

.product-list .table table tr {
  border-bottom: none;
}

.product-list .table table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.product-list .table table tbody {
  border-bottom: 0;
}

.product-list .table table tbody tr:hover {
  cursor: pointer;
}

.product-list .table table tbody .sell-action {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.product-list .table table tbody .td-sell-btn {
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 500;
  color: var(--brand-color);
  line-height: 24px;
  letter-spacing: 0em;
}

.confirm-sale-form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.confirm-sale-form div {
  margin-bottom: 10px;
  width: 100%;
}

.confirm-sale-form input {
  padding: 8px 5px;
  font-size: 18px;
  border: 1px solid #ddd;
  width: 100%;
  margin: 5px 0;
  outline: none;
}

.confirm-sale-form button {
  padding: 8px 35px;
  font-size: 14px;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-sale-form .sell-btn {
  background: #33b249;
  color: #fff;
  border: #33b249;
}

.confirm-sale-form span {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.confirm-sale-form .dark-btn {
  color: #ddd;
  border: 1px solid #000;
  background-color: #000;
}

.defaul-inventory-header span {
  background-color: transparent;
}

.defaul-inventory-header span h3 {
  background: none;
  display: flex;
  color: #cdcdcd;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  gap: 16px;
}

.defaul-inventory-header span h3 .inventory-routes.active-route {
  color: var(--brand-color);
}

.show_product_image {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1999;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
}

.show_product_image .close_btn {
  display: flex;
  justify-content: right;
  padding: 20px 50px;
}

.show_product_image .product_image_preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show_product_image .product_image_preview img {
  width: 90vw;
  max-height: fit-content;
}

.custom-ok-button {
  background-color: var(--brand-color);
  color: white; /* White text */
  border: none;
}

.custom-ok-button:hover {
  background-color: var(
    --brand-color
  ) !important; /* Slightly darker green on hover */
  border: none !important;
}

.custom-cancel-button {
  background-color: transparent;
  border: 1px solid var(--brand-color);
}

.custom-cancel-button:hover {
  background-color: transparent;
  color: var(--brand-color) !important;
  border: 1px solid var(--brand-color) !important;
}

.product-list .table table td .item_s_date {
  width: 100px;
}

.text_align_left {
  text-align: left !important;
}

.action-cell {
  position: sticky !important;
  right: 0;
  background-color: #fff;
  z-index: 1;
  // display: flex;
  box-shadow: -5px 0px 10px -5px rgba(0, 0, 0, 0.6) !important;
}

.action-cell > div {
  padding: 0 5px;
}

.action-header {
  position: sticky;
  right: 0;
  background-color: #fff;
  // padding: 0 10px;
  box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 2;
}

@media screen and (max-width: 430px) {
  .defaul-inventory-header span.inventory_header_search {
    width: 120px;
  }

  .sold_by_text h4 {
    font-size: 12px;
  }

  .defaul-inventory-header span h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    gap: 4px;
  }

  .product-list .table > h2 {
    font-size: 12px;
    padding: 8px 0;
  }

  .defaul-inventory-header span h3 a {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  .product-list .table {
    padding: 5px;
  }

  .product-list .table table {
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
  }

  .product-list .table table th {
    padding: 15px 12px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border: none;
  }

  .product-list .table table td {
    padding: 15px 10px;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    line-height: 12px;
  }

  .product-list .table table td .item_name {
    width: 100px;
  }

  .product-list .table table td .item_q {
    width: 80px;
  }

  .product-list .table table td .item_d {
    width: 110px;
  }

  .product-list .table table td .item_s_date {
    width: 80px;
  }

  .item_s_icons {
    width: 150px;
    display: flex;
    justify-content: center;
  }

  .product-list .table table th,
  .product-list .table table td {
    vertical-align: middle;
    border: none;
  }

  .product-list .table table th.icons > *,
  .product-list .table table td.icons > * {
    cursor: pointer;
  }

  .product-list .table table th.icons span img,
  .product-list .table table td.icons span img {
    width: 11px;
    height: 11px;
  }

  .product-list .table table tr {
    border-bottom: 0;
  }

  .product-list .table table tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .product-list .table table tbody {
    border-bottom: 0;
  }

  .product-list .table table tbody tr:hover {
    cursor: pointer;
  }

  .product-list .table table tbody .sell-action {
    font-size: 11px;
    line-height: 9px;
  }

  .product-list .table table tbody .td-sell-btn {
    font-size: 11px;
    line-height: 15px;
  }

  .actions span img {
    width: 11px;
    height: 11px;
  }

  .action-cell {
    position: sticky;
    right: 0;
    background-color: #fff;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.6) !important;
    z-index: 1;
  }

  .action-header {
    position: sticky;
    right: 0;
    background-color: #fff;
    padding: 0 10px;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
}
