.store-summary * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.store-summary {
  padding: 0 10px 0 20px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "t-products t-category t-stock-out"
    "sales-graph sales-graph store-value"
    "top-selling top-selling low-quantity";
  gap: 20px;
}

.store-summary.items {
  width: 100%;
}

.store-summary.items .item {
  // background-color: #fff;
  border-radius: 20px;
  padding: 26px 49.33px;
}

.store-summary.items .item img {
  margin-bottom: 12px;
}

.store-summary.items .item h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.store-summary.items .item p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.store-summary .item.total_products {
  grid-area: t-products;
  background: #f3f6ff;
  color: #003ffc;
}

.store-summary .item.total_category {
  grid-area: t-category;
  color: #d0a900;
  background: #fffbe9;
}

.store-summary .item.total_category p {
  color: #5a5a5a;
}

.store-summary .item.out_of_stock {
  grid-area: t-stock-out;
  background: #fdf3ff;
  color: #da20ff;
}

.store-summary .item.sales_graph {
  grid-area: sales-graph;
  background: #fff;
  height: 306px;
  padding: 20px;
}

.store-summary .item.sales_graph .graph_container {
  width: 100%;
  height: 90%;
}

.store-summary .item.sales_graph .chart-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-summary .item.sales_graph .chart-container-header h1 {
  color: #5a5a5a;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.store-summary .item.sales_graph .chart-container-header select {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 14px;
  outline: none;
  text-align: left;
}

.store-summary .item.store_value {
  grid-area: store-value;
  background: #f3fff4;
  padding: 96px 60px 96px 60px;
}

.store_value {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  img {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
  }

  .store-values {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    .value {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;

      h3 {
        font-size: 1.2rem;
        margin: 5px 0;
      }

      p {
        font-size: 0.9rem;
        color: #888;
      }
    }
  }
}

.store-summary .item.store_value h3 {
  color: var(--brand-color);
}

.store-summary .item.top_selling {
  grid-area: top-selling;
  padding: 0;
  background-color: #fff;
}

.store-summary .item.top_selling .item-header {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  box-shadow: 0 8px 16px -10px rgba(0, 0, 0, 0.1);
  align-items: center;
  width: 100%;
}

.store-summary .item.top_selling .item-header h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #5a5a5a;
}

.store-summary .item.top_selling tr th {
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: var(--brand-color);
}

.store-summary .item.top_selling tr {
  border: none;
}

.store-summary .item.top_selling tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.store-summary .item.top_selling tr td {
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-align: center;
  border: none;
  color: #5a5a5a;
}

.store-summary .item.top_selling .item-body {
  padding: 0 12px;
}

.store-summary .item.top_selling .item-body h1,
.store-summary .item.item.low_products .item-body h1 {
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
}

.store-summary .item.low_products {
  grid-area: low-quantity;
  padding: 0 0 30px;
  background-color: #fff;
}

.store-summary .item.low_products .item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 17px;
}

.store-summary .item.low_products .item-header h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #5a5a5a;
}

.item.low_products tr th {
  padding: 14px 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: var(--brand-color);
}

.item.low_products tr {
  border: none;
}

.item.low_products tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.item.low_products tr td {
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #5a5a5a;
}

.item.low_products .item-body {
  padding: 2px;
}

.info_card.out_of_stock {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  img {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
  }

  .out-of-stock-values {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    .value {
      display: flex;
      flex-direction: column;
      // align-items: center;

      h3 {
        font-size: 1.5rem;
        margin: 0;
      }

      p {
        font-size: 0.9rem;
        color: #555;
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .out-of-stock-values {
    flex-direction: column;

    .value {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 430px) {
  .store-summary {
    padding: 0 10px;
    width: unset;
    grid-template-areas:
      "t-products t-category"
      "t-stock-out store-value"
      "sales-graph sales-graph"
      "top-selling top-selling"
      "low-quantity low-quantity";
    gap: 20px;
  }

  .store-summary.items {
    width: 99%;
  }

  .store-summary .item.top_selling .item-body h1,
  .store-summary .item.item.low_products .item-body h1 {
    padding: 8px;
    font-size: 10px;
  }

  .store-summary.items .item {
    padding: 14px 15px;
    width: 100%;
  }

  .store-summary.items .item.info_card {
    display: flex;
    flex-direction: column;
  }

  .store-summary.items .item.info_card img {
    width: 16px;
    height: 16px;
  }

  .store-summary.items .item img {
    margin-bottom: 8px;
  }

  .store-summary.items .item h3 {
    font-size: 16px;
    line-height: 24px;
    width: fit-content;
  }

  .store-summary.items .item p {
    font-size: 12px;
    line-height: 18px;
    width: fit-content;
  }

  .store-summary .item.sales_graph {
    margin-top: 40px;
    background: #fff;
  }

  .store-summary .item.sales_graph .chart-container-header h1 {
    font-size: 10px;
    font-weight: 500;
    line-height: 13.08px;
    text-align: left;
  }

  .store-summary .item.sales_graph .chart-container-header select {
    font-size: 6.1px;
    font-weight: 500;
    line-height: 8.72px;
    outline: none;
    text-align: left;
  }

  .store-summary .item.top_selling {
    background-color: transparent;
  }

  .store-summary .item.top_selling .item-header {
    padding: 4px 20px;
  }

  .store-summary .item.top_selling .item-header h3 {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }

  .store-summary .item.top_selling .item-header a {
    font-size: 10px;
    line-height: 15px;
  }

  .store-summary .item.top_selling tr th {
    padding: 8px 15px;
    font-size: 10px;
    line-height: 20px;
    text-align: left;
  }

  .store-summary .item.top_selling tr td {
    padding: 8px 15px;
    font-size: 10px;
    line-height: 20px;
  }

  .store-summary .item.low_products {
    background-color: transparent;
  }

  .store-summary .item.low_products .item-header {
    padding: 8px 15px;
  }

  .store-summary .item.low_products .item-header h3 {
    font-size: 12px;
    line-height: 20px;
  }

  .store-summary .item.low_products .item-header a {
    font-size: 10px;
    line-height: 20px;
  }

  .item.low_products tr th {
    padding: 6px 8px;
    font-size: 10px;
    background: #fff;
    line-height: 18px;
  }

  .item.low_products tr td {
    padding: 8px;
    font-size: 10px;
    background: transparent;
    font-weight: 400;
    line-height: 21px;
  }

  .store_value {
    flex-direction: column;

    .store-values {
      display: flex;
      flex-direction: column;

      .value {
        flex-direction: column;
      }
    }
  }
}
