/* Cart items list */

.item-body-container {
  background: #fff;
  border-radius: 12px;
}

.cart-details-page .grid-item-header,
.grid-item-body {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-areas:
    "cat_1 cat_2 cat_3 cat_4 cat_5"
    "cat_1 cat_2 cat_3 cat_4 cat_5"
    "cat_1 cat_2 cat_3 cat_4 cat_5"
    "cat_1 cat_2 cat_3 cat_4 cat_5";
  align-items: center;
  text-align: center;
}

.cart-details-page .grid-item-body .cart_item_1 {
  grid-area: cat_1;
}

.cart-details-page .grid-item-body .cart_item_2 {
  grid-area: cat_2;
  /* background-color: red; */
}

.cart-details-page .grid-item-body .cart_item_2 .set_quantity, .cart_item_3.set_price{
  display: flex;
  justify-content: center;
  gap: 15px;
}

.cart_item_3.set_price {
  display: flex;
  justify-content: center;
}

.cart-details-page .grid-item-body .cart_item_2 .set_quantity h4,
.set_price h4 {
  outline: none;
  border-bottom: 1px solid var(--brand-color);
  width: max-content;
}

.cart-details-page .grid-item-body .cart_item_2 .set_quantity h4 {
  min-width: 12px;
}

.cart-details-page .grid-item-body .cart_item_3 {
  grid-area: cat_3;
}

.cart-details-page .grid-item-body .cart_item_4 {
  grid-area: cat_4;
}

.cart-details-page .grid-item-body .cart_item_5 {
  grid-area: cat_5;
}

.cart-details-page .grid-item-body {
  margin-bottom: 30px;
}

.cart-details-page .grid-item {
  padding: 10px;
  width: 250px;
  /* text-align: center; */
}

.cart-details-page .grid-item.header {
  font-weight: bold;
  text-align: center;
  z-index: none;
}

.cart-details-page .grid-item.header h1 {
  text-align: center;
  width: 100%;
  padding: 26px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cart-details-page .header {
  background: none;
  box-shadow: none;
}

.cart-details-page .grid-item.body {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #767676;
}

.cart-details-page .cart-body .grid-item.body button {
  border: none;
  background: none;
}

.cart-details-page .cart-body .grid-item.body input {
  outline: none;
  background: transparent;
  border: 1px solid var(--brand-color);
  width: 100px;
  padding: 12px;
  margin: 0 10px;
}

.cart-details-page .cart-body .grid-item.body input.actions-quantity {
  width: 50px;
}

.cart-details-page .cart-body .grid-item.body button.actions-quantity {
  border: 1px solid #767676 !important;
  margin: 0 10px;
  padding: 4px;
}

.cart-details-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-details-page .cart-body {
  width: 90%;
}

/* cart info */
.cart-body .cart-info {
  margin: 80px 0;
}

.cart-body .cart-info > div {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}

.cart-body .customer-info {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 40px;
}

.cart-body .customer-info > div {
  width: 100%;
  margin-bottom: 16px;
}

.cart-body .customer-info > div.check-out-actions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
}

.cart-body .customer-info > div.check-out-actions button {
  background: var(--brand-color);
  color: #fff;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  line-height: 24px;
  border: none;
}

.cart-body .customer-info > div.check-out-actions button.return-btn {
  background: transparent;
  width: 100%;
  color: var(--brand-color);
  border: 1px solid var(--brand-color);
}

.cart-body .customer-info > div label {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  padding: 16px;
}

.cart-body .customer-info > div input,
.cart-body .customer-info > div select {
  width: 100%;
  padding: 24px;
  border: 1px solid var(--brand-color);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  outline: none;
}

.cart-body .customer-info h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 30px;
}

.customer-info .name_field_customer_info {
  position: relative;
}

.suggestions-container {
  border: 1px solid #ccc;
  background: #fff;
  position: absolute;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
}

.suggestion {
  padding: 10px;
  cursor: pointer;
}

.suggestion:hover {
  background-color: #f0f0f0;
}

.update_payment_container {
  padding: 100px 200px;
  border: 1px solid var(--brand-color);
  border-radius: 15px;
  text-align: center;
  margin: auto;
  width: 90%;
  font-size: 28px;
}

.update_payment_container a{
  font-size: 28px;
  color: var(--brand-color);
}

.grace_period_container {
  display: flex;
  justify-content: center;
  padding: 15px;
  margin: auto;
  text-align: center;
  border: 1px solid var(--brand-color);
  width: 90%;
  border-radius: 12px;
}

@media screen and (max-width: 430px) {
  .cart-details-page .grid-item-header,
  .grid-item-body {
    grid-template-areas:
      "cat_1 cat_5"
      "cat_3 cat_5"
      "cat_2 cat_5"
      "cat_4 cat_5";
    align-items: center;
    padding: 10px 40px;
  }

  .cart-details-page .cart-body,
  .cart-body .customer-info {
    width: 95%;
  }

  .cart-details-page .grid-item {
    padding: 10px;
    width: 180px;
    /* text-align: center; */
  }

  .cart-details-page .grid-item.header {
    display: none;
  }

  .cart-details-page .grid-item.body {
    font-size: 12px;
  }

  .cart-details-page .cart-body .grid-item.body input {
    width: 60px;
    padding: 5px;
    margin: 0 5px;
  }

  .cart-details-page .cart-body .grid-item.body input.actions-quantity {
    width: 17px;
  }

  .cart-details-page .cart-body .grid-item.body button.actions-quantity {
    margin: 0 5px;
    padding: 4px;
  }

  .cart-body .customer-info > div input,
  .cart-body .customer-info > div select {
    padding: 16px;
    border-radius: 12px;
    font-size: 11px;
  }

  .cart-body .customer-info > div label {
    font-size: 10px;
    line-height: 16px;
    padding: 10px;
  }

  .cart-body .customer-info > div.check-out-actions {
    gap: 10px;
  }

  .cart-body .customer-info > div.check-out-actions button {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .update_payment_container {
    padding: 30px 20px;
    width: 90%;
    border: 1px solid var(--brand-color);
    border-radius: 15px;
    font-size: 16px;
    text-align: center;
  }
  
  .update_payment_container a{
    font-size: 16px;
    color: var(--brand-color);
  }
}
