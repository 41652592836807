.home {
  padding: 0 120px;
}

.hero {
  background: #fcfffc no-repeat center center/cover;
  height: 100vh;
  /* display: flex; */
  margin-top: 80px;
  justify-content: center;
  /* align-items: center; */
  border-radius: 10px;
  text-align: center;
  padding: 60px 40px;
  /* font-size: 2rem; */
  /* font-weight: 700; */
  /* position: relative; */
  /* background-color: blue; */
}

.hero h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.2em;
  text-align: center;
}

.hero h1 {
  /* font-family: Offside; */
  font-size: 42px;
  font-weight: 700;
  line-height: 71px;
  letter-spacing: 0em;
  text-align: center;
}

.hero h1 .inventory {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
}

.hero p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  margin-bottom: 30px;
  text-align: center;
}

.hero .hero-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hero .hero-buttons a {
  font-size: 18px;
  transition: all 0.3s ease;
}

.hero .hero-image {
  position: relative;
  top: 450px;
  left: 50%;
  width: 90%;
  height: 740px;
  background: #ddd no-repeat center center/cover;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.hero .hero-image iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.hero .hero-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 0.3s ease;
}

.hero .hero-image-overlay button,
.hero .hero-image-overlay i,
.hero .hero-image-overlay a {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  transition: all 0.3s ease;
}

.hero .hero-image:hover .hero-image-overlay {
  visibility: visible;
  transition: all 0.3s ease;
}

.hero .hero-image-overlay button {
  background: var(--brand-color);
  font-size: 17.29px;
  font-weight: 400;
  line-height: 25.94px;
  padding: 13px 34px;
  border-radius: 25px;
  border: none;
}

.special-feature {
  padding: 60px 0;
  width: 100%;
  border-radius: 10px;
  margin-top: 500px;
}

.special-feature .feature-header {
  width: 100%;
  padding: 0 0 40px;
  text-align: center;
}

.special-feature .feature-header p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.special-feature .feature-header h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.special-feature .special-feature-cards {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}

.special-feature .special-feature-cards .special-feature-card {
  background: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 280px;
  height: 280px;
  text-align: center;
  transition: all 0.3s ease;
}

.special-feature .special-feature-cards .special-feature-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.special-feature .special-feature-cards .special-feature-card img {
  width: 60px;
  height: 40px;
  margin-bottom: 20px;
}

.special-feature .special-feature-cards .special-feature-card h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.special-feature .special-feature-cards .special-feature-card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.special-plans {
  padding: 60px 0;
  width: 100%;
  border-radius: 10px;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

.special-plans .plans-text {
  width: 576px;
  height: 256px;
  gap: 16px;
}

.plans-text h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.plans-text h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.plans-text p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 32px;
}

.plans-image {
  width: 300px;
  height: 300px;
  background: #295f2d no-repeat center center/cover;
  border-radius: 100px;
  margin-right: 80px;
  position: relative;
}

.plans-image .plan {
  position: absolute;
  height: 54px;
  padding: 12px 24px 12px 24px;
  border-radius: 20px;
  gap: 12px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.plans-image .plan img {
  width: 30px;
  height: 30px;
}

.basic {
  top: 28px;
  left: -108px;
  width: 172px;
  background: #d4f0ff;
}

.standard {
  top: 123px;
  left: 176px;
  width: 204px;
  background: #fff1b7;
}

.pro {
  top: 220px;
  left: -56px;
  width: 226px;
  background: #eaffeb;
}

.connect {
  padding: 100px 120px;
  width: 100%;
  border-radius: 10px;
  margin-top: 120px;
  gap: 32px;
  display: flex;
  justify-content: space-between;
  background: #f3fff4;
  align-items: center;
}

.connect-text h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 32px;
  line-height: 60px;
}

.connect-text p {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 32px;
  line-height: 36px;
}

.testimonials {
  padding: 60px 120px;
  width: 100%;
  border-radius: 10px;
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  gap: 80px;
  align-items: center;
}

.testimonials .testimonials-header {
  width: 40%;
  gap: 16px;
}

.testimonials .testimonials-header p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  /* margin-bottom: 14px; */
}

.testimonials .testimonials-header h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.testimonial-body {
  width: 60%;
}

.testimonials .testimonials-cards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 60px;
}

.testimonials .testimonials-cards .testimonials-card {
  margin: 0 auto;
  text-align: center;
  padding: 30px 10px 10px;
  border-radius: 20px;
  width: 310px;
  position: relative;
  /* height: 200px; */
}

.testimonials .card-1 {
  background: var(--card-color-1);
}

.testimonials .card-2 {
  background: var(--card-color-2);
}

.testimonials .card-3 {
  background: var(--card-color-3);
}

.testimonials-card-header img {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  top: -20%;
  left: 40%;
}

.testimonials-card h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.testimonials-card p {
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.testimonials .testimonials-cards.single {
  display: block;
  position: relative;
}

.testimonials .testimonials-cards.single .testimonials-card {
  width: 310px;
  height: 196px;
  text-align: center;
  position: relative;
  padding: 30px 10px 10px;
}

.faq {
  padding: 60px 120px;
  width: 100%;
  border-radius: 10px;
  margin-top: 60px;
  gap: 80px;
}

.faq .faq-header h2 {
  color: #295f2d;
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  margin-bottom: 80px;
}

.faq .faq-question {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 80px;
  gap: 120px;
}

.faq .faq-question h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}

.faq .faq-question p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

@media screen and (max-width: 430px) {
  .home {
    padding: 0 15px;
  }

  .hero {
    height: unset;
    margin-top: 60px;
    padding: 30px;
  }

  .hero h6 {
    font-size: 10px;
    line-height: 15px;
  }

  .hero h1 {
    padding: 0 10px;
  }

  .hero h1,
  .hero h1 .inventory {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  .hero p {
    font-size: 10px;
    line-height: 15px;
  }

  .hero .hero-buttons {
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .hero .hero-buttons button {
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
  }

  .hero .hero-buttons .--btn-secondary {
    border: 1px solid var(--brand-color);
  }

  .hero .hero-image {
    top: 430px;
    left: 50%;
    width: 100%;
    height: 700px;
  }

  .hero .hero-image-overlay a,
  .hero .hero-image-overlay i {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    transition: all 0.3s ease;
  }

  .special-feature {
    padding: 60px 0;
    margin-top: 140px;
  }

  .special-feature .feature-header p {
    font-size: 14px;
    line-height: 18px;
  }

  .special-feature .feature-header h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .special-feature .special-feature-cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    padding: 40px 0;
  }

  .special-feature .special-feature-cards .special-feature-card {
    padding: 14px 20px;
    width: 100%;
    height: unset;
  }

  .special-feature .special-feature-cards .special-feature-card img {
    width: 30px;
    height: 30px;
  }

  .special-feature .special-feature-cards .special-feature-card h3 {
    font-size: 22px;
    line-height: 18px;
  }

  .special-feature .special-feature-cards .special-feature-card p {
    font-size: 10px;
    line-height: 15px;
  }

  .special-plans {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }

  .special-plans .plans-text {
    width: 100%;
    height: unset;
    text-align: center;
    margin-bottom: 48px;
    gap: 16px;
  }

  .plans-text h3 {
    font-size: 12px;
    line-height: 18px;
  }

  .plans-text h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .plans-text p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
  }

  .plans-text button {
    margin: auto;
    font-size: 10px;
    line-height: 15px;
    padding: 10px 16px;
  }

  .plans-image {
    width: 200px;
    height: 200px;
    border-radius: 50px;
    margin: auto;
  }

  .plans-image .plan {
    height: unset;
    width: unset;
    padding: 8px 12px;
    align-items: center;
    font-size: 8px;
    line-height: 12px;
  }

  .plans-image .plan img {
    width: 16px;
    height: 16px;
  }

  .special-plans .basic {
    top: 48px;
    left: -47px;
    width: unset;
  }

  .special-plans .standard {
    top: 86px;
    left: 145px;
    width: 109px;
  }

  .special-plans .pro {
    top: 142px;
    left: -27px;
    width: unset;
  }

  .connect {
    padding: 30px 15px;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    gap: 0;
    flex-direction: column;
  }

  .connect .connect-image img {
    width: 300px;
    /* height: 116px; */
    object-fit: cover;
  }

  .connect-text {
    text-align: center;
  }

  .connect-text button {
    margin: auto;
    font-size: 10px;
    line-height: 15px;
    padding: 10px 16px;
  }

  .connect-text h2 {
    font-size: 20px;
    margin-bottom: 8px;
    line-height: 30px;
  }

  .connect-text p {
    font-size: 12px;
    /* margin-bottom: 32px; */
    line-height: 18px;
  }

  .testimonials {
    padding: 60px 15px;
    width: 100%;
    margin-top: 80px;
    flex-direction: column;
  }

  .testimonials .testimonials-header {
    width: 100%;
    text-align: center;
    gap: 0;
  }

  .testimonials .testimonials-header p {
    font-size: 12px;
    line-height: 18px;
  }

  .testimonials .testimonials-header h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .testimonial-body {
    width: 100%;
  }

  .testimonials .testimonials-cards {
    grid-template-columns: 1fr;
    gap: 60px;
    margin-bottom: 60px;
    width: 100%;
  }

  .testimonials .testimonials-cards .testimonials-card {
    margin: 0 auto;
    text-align: center;
    padding: 30px 40px 10px;
    width: 100%;
    position: relative;
    height: unset;
  }

  .testimonials-card-header img {
    position: absolute;
    top: -24%;
    left: 40%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .testimonials-card h3 {
    font-size: 12px;
    line-height: 18px;
  }

  .testimonials-card p {
    font-size: 10px;
    line-height: 15px;
  }

  .testimonials-card p img {
    width: 10px;
    height: 30px;
  }

  .testimonials .testimonials-cards.single {
    display: block;
    width: 100%;
    position: relative;
  }

  .testimonials .testimonials-cards.single .testimonials-card {
    width: 100%;
    height: unset;
    text-align: center;
    position: relative;
    padding: 30px 40px 10px;
  }

  .faq {
    padding: 0 15px;
  }

  .faq .faq-header h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  .faq .faq-question {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 80px;
    gap: 24px;
  }

  .faq .faq-question h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .faq .faq-question p {
    font-size: 12px;
    line-height: 18px;
  }
}
