.sales-page .table {
  width: 100%;
  overflow-x: auto;
}

.sales-page .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  min-width: 100%;
  overflow-x: auto;
}

.sales-page .salesList-table {
  width: 100%;
  overflow-x: auto;
}


.sales-page .dynamic-content {
  width: 100% !important;
  border-radius: 12px;
  box-sizing: border-box;
}


.sales-page .dynamic-content tbody tr.dynamically-display-header,
.sales-page .dynamic-content tbody tr.dynamically-display-body {
  /* background: red !important; */
  border-radius: 12px;
  margin: 0 10px 0 10px;
}

.sales-page tbody .dynamically-display-header th {
  color: var(--brand-color);
  font-size: 16px;
  line-height: 24px;
  background: transparent !important;
}

.sales-page tbody .dynamically-display-body td {
  color: #767676;
  font-size: 16px;
  line-height: 24px;
  background: transparent !important;
  /* width: 100%; */
} 

.icons {
  position: relative;
}

.actions-spans {
  /* position: relative; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 12px;
  /* margin: 0 0 0 140px; */
}

.actions-spans img {
  width: 18px;
  height: 18px;
}

.product-group-list .actions-spans {
  margin: 0;
}

.actions-spans span {
  display: flex;
}

.actions-spans span.noclick {
  pointer-events: none;
  color: grey;
}

.actions-spans button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.share-receipt-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.share-receipt-content > span {
  font-size: 10px;
  cursor: pointer;
}

.share-receipt-content > a {
  display: flex;
  gap: 10px;
  align-items: center;
}

.share-receipt-content a img {
  width: 16px;
  height: 16px;
}

.share-receipt-content a .share_text {
  display: block;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.delivery-status-dropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.delivery-status-dropdown > span {
  display: block;
  /* text-align: center; */
  line-height: 6px;
  font-size: 8px;
}



.delivery-status-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  width: max-content;
  max-height: max-content;
  padding: 5px 0;
  border-radius: 5px;
}

.delivery-status-options div {
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
}

.delivery-status-options div:hover {
  background-color: #f0f0f0;
}

.delivery-status-options .option.active {
  background-color: var(--brand-color);
  color: #ffffff;
}


@media screen and (max-width: 430px) {
  .no-products-p {
    font-size: 10px;
  }

  .sales-page .table {
    padding: 5px;
  }

  .delivery-status-dropdown > span {
    display: block;
    text-align: center;
    line-height: 6px;
    font-size: 5px;
  }

  .delivery-status-dropdown {
    width: max-content;
  }

  .sales-page .table table {
    border-collapse: collapse;
    width: 100%;
    min-width: 100%;
    overflow-x: auto;
    display: block;
  }

  .sales-page .table table > thead > tr > th:nth-child(2) {
    font-weight: 400;
    min-width: 100px;
  }
  
  .sales-page .table table > tbody > tr > td:nth-child(2) {
    font-weight: 400;
    min-width: 100px;
  }

  .sales-page tbody .dynamically-display-header th {
    font-size: 8px;
    line-height: 9px;
  }

  .sales-page tbody .dynamically-display-body td {
    color: #767676;
    font-size: 8px;
    line-height: 9px;
  }
}
