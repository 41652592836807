.group-items-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.items-list {
  padding: 0;
  border-right: 1px solid #5a5a5a;
  min-height: 100vh;
  width: 367px;
}

.items-list > h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  padding: 34px 34px 22px;
  color: #767676;
}

.group-items .group-item {
  display: flex;
  align-items: center;
  padding: 0 100px 0 20px;
  cursor: pointer;
  gap: 20px;
  margin-bottom: 12px;
  background: #f5f4f4;
}

.group-items .group-item.active-group-item {
  background: var(--brand-color);
}

.group-items .group-item.active-group-item h3 {
  color: #f3fff4;
}

.group-items .group-item.active-group-item span {
  color: #f3fff4;
}

.group-items .group-item h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  letter-spacing: 0em;
  padding: 10px 0;
  color: #767676;
}

.group-items .group-item h3 span {
  display: inline-block;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  padding: 0 0 0 8px;
  color: #767676;
}

.group-items .group-item .item-dot {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin: 14px 0 14px 0;
  background: #e2e2e2;
}

.item-details {
  padding: 0 47px;
  /* width: 100%; */
}

.item-details .details-header {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
}

.item-details .details-header .actions img {
  padding: 10px;
  border-radius: 10px;
  background: #f3f3f3;
  cursor: pointer;
}

.item-details .details-header .actions {
  display: flex;
  gap: 16px;
}

.item-details .details-header .actions button {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #f3fff4;
  border: none;
  background-color: var(--brand-color);
  padding: 10px 20px;
  border-radius: 10px;
}

.item-details .details-body {
  margin: 32px 0 80px;
}

.item-details .details-body h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  color: #767676;
}

.item-details .details-body .details_body_header .dates {
  color: #767676;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 15px;
}

.item-details .details-body h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #767676;
}

.item-details .details-body table {
  margin-top: 60px;
}

.item-details .details-body table tr {
  padding: 44px 0;
  border-bottom: none;
}

.item-details .details-body table tr td {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #767676;
}

.item-details .details-body table tr td span {
  background: #bdbdbd;
  color: #767676;
  display: inline-block;
  font-size: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 3px;
  padding: 2px 8px;
}

.item-details .details-body-items table tr {
  border-top: 1px solid #5a5a5a;
  border-bottom: 1px solid #5a5a5a;
}

.item-details .details-body-items table tr th {
  font-size: 16px;
  padding: 10px 40px;
  font-weight: 400;
  line-height: 24px;
  color: #767676;
  text-align: center;
}

.item-details .details-body-items table tr td {
  font-size: 12px;
  padding: 10px 0;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  color: var(--brand-color);
  letter-spacing: 0em;
}

.delete_product_group_button {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
  /* border: 1px solid red; */
}

.delete_product_group_button button {
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(235, 87, 87, 1);
  display: flex;
  align-items: center;
  gap: 5px;
}

.product_group_image {
  width: 100%;
  border-radius: 12px;
  width: 500px;
  height: 200px;
}

.product_group_image > img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .group-items-container {
    grid-template-columns: 1fr;
  }

  .group-items .group-item {
    display: flex;
    padding: 0 20px;
    width: 100%;
    gap: 20px;
    margin-bottom: 12px;
  }

  .items-list {
    border: none;
    margin: 0 auto;
    width: 95%;
  }

  .items-list > h1 {
    font-size: 16px;
    line-height: 24px;
    padding: 20px 10px;
  }

  .item-details {
    display: none;
  }

  .item-details.show_details {
    display: block;
    position: absolute;
    background: #f9f9f9;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
  }

  .item-details .details-header .actions {
    gap: 0;
  }

  .item-details .actions .btn_edit_add_item {
    display: none;
  }

  .item-details .details-header {
    flex-direction: row-reverse;
    align-items: center;
    padding: 18px 14px;
    background: var(--brand-color);
  }

  .item-details .details-body {
    margin: 0 0 40px;
  }

  .item-details .details-body .details_body_header {
    background: var(--brand-color);
    margin: 0;
    padding: 36px 18px;
  }

  .item-details .details-body .details_body_header .dates {
    color: #ffffff;
    font-size: 10px;
    line-height: 15px;
  }

  .item-details .details-body .details_body_header h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #ffffff;
  }

  .item-details .details-body .details_body_header h5 {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #ffffff;
  }

  .item-details .details-body table tr {
    padding: 0 18px;
    border-bottom: none;
  }

  .item-details .details-body table tr td {
    padding-left: 18px;
    font-size: 14px;
    line-height: 21px;
  }

  .item-details .details-body-items {
    width: 95%;
    overflow-x: scroll;
    background: #ffffff;
    margin: auto;
    border-radius: 12px;
    padding: 20px 10px;
  }

  .item-details .details-body-items table tr {
    border: none;
  }

  .item-details .details-body-items table tr th {
    font-size: 10px;
    padding: 10px 0;
    min-width: 100px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
  }

  .item-details .details-body-items table tr td {
    font-size: 10px;
    min-width: 100px;
    padding: 10px 0;
    text-align: center;
    line-height: 15px;
  }

  .delete_product_group_button {
    margin-top: 100px;
  }
  
  .delete_product_group_button button {
    font-size: 10px;
    line-height: 15px;
  }
  .delete_product_group_button button img{
    width: 12px;
    height: 12px;
    color: blue;
  }



  .product_group_image {
    width: 90%;
    margin: auto;
  }
}
