.admin_page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.admin_page h1 {
  text-align: center;
  color: #343a40;
  margin-bottom: 20px;
}

.admin_page .summary {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    color: #343a40;
    font-weight: bold;
}

.admin_page .search_bar {
    margin-bottom: 20px;
    text-align: center;
}

.admin_page .search_bar input {
    padding: 10px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.admin_page .search_bar input:focus {
    outline: none;
    /* border-color: #007bff; */
}


.admin_page .business_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.admin_page .business_card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.admin_page .business_card:hover {
  transform: translateY(-5px);
}

.admin_page .business_image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.admin_page h2 {
  margin-top: 0;
  color: var(--brand-color);
}

.admin_page p {
  margin: 5px 0;
  color: #555;
}

.admin_page strong {
  color: #343a40;
}

.admin_page .sales_rep {
  margin-top: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.admin_page h3 {
  margin-top: 20px;
  color: #343a40;
  border-bottom: 2px solid var(--brand-color);
  padding-bottom: 5px;
}



/* pagination */

.pagination {
    margin-top: 20px;
    text-align: center;
}

.pagination ul {
    list-style: none;
    display: inline-flex;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    padding: 8px 12px;
    border: 1px solid var(--brand-color);
    border-radius: 4px;
    color: var(--brand-color);
    text-decoration: none;
    cursor: pointer;
}

.pagination li a:hover {
    background-color: var(--brand-color);
    color: #fff;
}

.pagination li.active a {
    background-color: var(--brand-color);
    color: #fff;
    cursor: default;
}
