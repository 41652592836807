.cart-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.actions{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 50%;
}

table{
    width: 100%;
    border-collapse: collapse;
}

table tr{
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    text-align: left;
}

table td{
    padding: 10px 0;
}

.cart-item label{
    width: 30px;
    height: 30px;
    display: inline-block;
    /* justify-content: center;
    align-items: center; */
    border: 1px solid #ddd;
    border-radius: 50%;
}