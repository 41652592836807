.pricing {
    padding: 80px 120px;
}

.pricing .pricing-header p {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    text-align: center;
    color: #5A5A5A;
    padding: 0 37px;
}

.pricing .pricing-header h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0em;
    color: var(--brand-color);
    text-align: center;
}

.subscription-interval {
    display: flex;
    justify-content: center;

}

.subscription-interval__tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 50px;
    width: fit-content;
    padding: 20px 40px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
}

.subscription-interval__tabs__tab button {
    background: transparent;
    border: none;
    padding: 12px 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    border-radius: 10px;
    color: var(--brand-color);
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.subscription-interval__tabs__tab button.active-interval {
    background: var(--brand-color);
    color: #E5E5E5;
    transition: background 0.3s ease, color 0.3s ease;
}


.pricing-card-1 p {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    margin-top: 14px;
    text-align: center;
    color: var(--brand-color);
}

.features {
    margin-top: 80px;
    border-collapse: collapse;
    width: 100%;
    position: relative;
}

.features .features-header {
    border-bottom: none;
    position: sticky;
    top: 0;
    z-index: 1;
}

.features .features-header .features-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: var(--brand-color);
}

.features .features-header h3 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 36px;
    color: var(--brand-color);
}

.features .features-header button {
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    padding: 0 35px;
    border: none;
    background: transparent;
    border: 1px solid var(--brand-color);
    border-radius: 40px;
    color: var(--brand-color);
}

.features .features-header button.active-feature {
    background: var(--brand-color);
    color: #E5E5E5;
}

.features .features-header th {
    text-align: center;
}


.features tr td:first-child {
    text-align: left;
}

.features tr td {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    padding: 40px 0;
    color: #5A5A5A;
}

.pricing .pricing-card-2 {
    padding: 40px 0;
    border: 1px solid #E5E5E5;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
}

.pricing .pricing-card-2 h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    color: #5A5A5A;
}

.pricing .pricing-card-2 button {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 10px 25px;
    border: none;
    background: var(--brand-color);
    border-radius: 30px;
    color: #E5E5E5;
}


@media screen and (max-width: 430px) {
    .pricing {
        padding: 80px 15px;
    }

    .pricing .pricing-header {
        width: 100%;
        margin-bottom: 49px;
    }

    .pricing .pricing-header p {
        font-size: 12px;
        line-height: 18px;
        padding: 0;
        margin: 0;
    }
    
    .pricing .pricing-header h1 {
        font-size: 24px;
        line-height: 36px;
    }

    .subscription-interval__tabs__tab button {
        font-size: 12px;
        line-height: 18px;
        border-radius: 12px;
        padding: 6px 16px;
    }

    .subscription-interval__tabs {
        margin: 0;
        padding: 8px 24px;
    }

    .pricing-card-1 p {
        font-size: 12px;
        line-height: 18px;
    }
    
    .features {
        margin-top: 80px;
        border-collapse: collapse;
        width: 100%;
        position: relative;
        overflow-x: scroll;
    }

    .features table {
        width: 400px;
    }
    
    .features .features-header .features-text {
        font-size: 16px;
        line-height: 24px;
    }
    
    .features .features-header h3 {
        font-size: 12px;
        line-height: 18px;
    }
    
    .features .features-header button {
        font-size: 10px;
        line-height: 15px;
        padding: 6px 14px;
        border-radius: 40px;
    }

    .features tr td {
        font-size: 12px;
        line-height: 18px;
        padding: 24px 0;
    }

    .pricing .pricing-card-2 h3 {
        font-size: 12px;
        line-height: 18px;
    }
    
    .pricing .pricing-card-2 button {
        font-size: 10px;
        line-height: 15px;
        padding: 12px 32px;
        border-radius: 24px;
    }
    
    
    
}