.subscribe-container {
  position: relative;
  margin-right: 130px;
}

.subscribe-container > h1 {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--brand-color);
}

.subscribe-container .subscribe-grid-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.subscribe-container .subscribe-grid-items .item {
  border: 1px solid var(--brand-color);
  border-radius: 20px;
  background: white;
  padding: 67px 36px 20px;
}

.subscribe-container .subscribe-grid-items .item h3 {
  color: var(--brand-color);
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
  font-weight: 500;
}

.subscribe-container .subscribe-grid-items .item p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 20px;
}

.subscribe-container .subscribe-grid-items .item h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #171717;
  margin-bottom: 18px;
}

.subscribe-container .subscribe-grid-items .item h1 > span {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}

.subscribe-container .subscribe-grid-items .item .choose-btn {
  display: flex;
}

.subscribe-container .subscribe-grid-items .item .choose-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  background-color: var(--brand-color);
  padding: 8px 24px;
  border-radius: 24px;
  cursor: pointer;
  color: #f3fff4;
  margin: 0 auto;
}

.payment-interface {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 200;
  align-items: center;
  overflow-y: auto;
}

.payment-interface .pay-container {
  display: flex;
  border-radius: 20px;
  width: 60%;
  box-shadow: 2px 4px 40px 0px #0000000d;
}

.payment-interface .pay-container .pay-left-nav {
  width: 20%;
  background-color: var(--brand-color);
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
}

.payment-interface .pay-container .pay-left-nav h3 {
  margin: 40px auto 18px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
}

.payment-interface .pay-container .pay-left-nav hr {
  border-top: 2px solid #ffffff;
  background-color: #fff;
  margin-bottom: 32px;
}

.payment-interface .pay-container .pay-left-nav .card-icon-container {
  display: flex;
  align-items: center;
  margin: 0 20px;
  gap: 10px;
}

.payment-interface .pay-container .pay-left-nav .card-icon-container h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.payment-interface .pay-container .pay-main-content {
  width: 80%;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 12px;
}

.payment-interface .pay-container .pay-main-content .pay-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.payment-interface .pay-container .pay-main-content .pay-main-header .logo {
  text-align: center;
}

.payment-interface .pay-container .pay-main-content .pay-main-header .logo img {
  width: 29px;
  height: 34px;
}

.payment-interface .pay-container .pay-main-content .pay-main-header .logo h3 {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-main-header
  .company-pay-info
  h3 {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: right;
  color: #5a5a5a;
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-main-header
  .company-pay-info
  h1 {
  font-size: 10px;
  text-align: right;
  font-weight: 400;
  line-height: 15px;
  color: #5a5a5a;
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-main-header
  .company-pay-info
  h1
  > span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 4px;
  color: var(--brand-color);
}

.payment-interface .pay-container .pay-main-content > hr {
  margin-bottom: 50px;
}

.payment-interface .pay-container .pay-main-content > h1 {
  color: var(--brand-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.payment-interface .pay-container .pay-main-content .pay-form {
  margin: 0 20px;
}

.payment-interface .pay-container .pay-main-content .pay-form .form-field {
  width: 100%;
  display: flex;
  gap: 40px;
  margin-bottom: 12px;
}

.payment-interface .pay-container .pay-main-content .pay-form .form-field div {
  width: 100%;
  /* display: flex; */
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-form
  .form-field
  div
  label {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-form
  .form-field
  div
  input {
  width: 100%;
  outline: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  border: 1px solid #171717;
  padding: 16px 20px;
  margin-bottom: 24px;
}

.payment-interface
  .pay-container
  .pay-main-content
  .pay-form
  .form-field
  div
  input[type="submit"] {
  border: none;
  background-color: var(--brand-color);
  color: #fff;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
}

.payment-interface .pay-container .pay-main-content p {
  text-align: center;
  font-size: 12px;
  margin-bottom: 100px;
}

@media screen and (max-width: 430px) {
  .subscribe-container {
    margin-right: 0;
  }

  .subscribe-container > h1 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .subscribe-container .subscribe-grid-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 40px;
  }

  .subscribe-container .subscribe-grid-items .item {
    border: 1px solid var(--brand-color);
    border-radius: 20px;
    background: white;
    padding: 16px 20px;
  }

  .subscribe-container .subscribe-grid-items .item .item-text {
    display: flex;
    align-items: center;
  }

  .subscribe-container .subscribe-grid-items .item h3 {
    font-size: 14px;
    line-height: 21px;
  }

  .subscribe-container .subscribe-grid-items .item p {
    font-size: 12px;
    line-height: 18px;
  }

  .subscribe-container .subscribe-grid-items .item h1 {
    font-size: 16px;
    line-height: 24px;
  }

  .subscribe-container .subscribe-grid-items .item h1 > span {
    font-size: 12px;
    line-height: 18px;
  }

  .subscribe-container .subscribe-grid-items .item .choose-btn > span {
    font-size: 8px;
    line-height: 12px;
    border-radius: 12px;
  }

  .payment-interface .pay-container {
    display: flex;
    border-radius: 20px;
    width: 95%;
  }

  .payment-interface .pay-container .pay-left-nav {
    width: 30%;
    padding: 0;
  }

  .payment-interface .pay-container .pay-left-nav h3 {
    margin: 40px auto 18px;
    font-size: 8px;
    line-height: 12px;
  }

  .payment-interface .pay-container .pay-left-nav hr {
    border-top: 2px solid #ffffff;
    background-color: #fff;
    margin-bottom: 32px;
  }

  .payment-interface .pay-container .pay-left-nav .card-icon-container {
    display: flex;
    align-items: center;
    margin: 0 12px;
    gap: 8px;
  }

  .payment-interface .pay-container .pay-left-nav .card-icon-container img {
    width: 13.33px;
    height: 9.33px;
  }

  .payment-interface .pay-container .pay-left-nav .card-icon-container h5 {
    font-size: 10px;
    line-height: 15px;
  }

  .payment-interface .pay-container .pay-main-content {
    width: 70%;
    padding: 8px;
  }

  .payment-interface .pay-container .pay-main-content .pay-main-header {
    padding: 0 0 10px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-main-header
    .logo
    img {
    width: 28px;
    height: 32px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-main-header
    .company-pay-info
    h3 {
    font-size: 8px;
    line-height: 12px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-main-header
    .company-pay-info
    h1 {
    font-size: 8px;
    line-height: 12px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-main-header
    .company-pay-info
    h1
    > span {
    font-size: 12px;
    line-height: 18px;
  }

  .payment-interface .pay-container .pay-main-content > hr {
    margin-bottom: 50px;
  }

  .payment-interface .pay-container .pay-main-content > h1 {
    font-size: 10px;
    line-height: 15px;
  }

  .payment-interface .pay-container .pay-main-content .pay-form {
    margin: 0 15px;
  }

  .payment-interface .pay-container .pay-main-content .pay-form .form-field {
    flex-direction: column;
    gap: 0;
    margin-bottom: 0;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-form
    .form-field
    div
    label {
    font-size: 10px;
    line-height: 15px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-form
    .form-field
    div
    input {
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    padding: 9px 20px;
    margin-bottom: 24px;
  }

  .payment-interface
    .pay-container
    .pay-main-content
    .pay-form
    .form-field
    div
    input[type="submit"] {
    padding: 9px 0;
    font-size: 12px;
    line-height: 18px;
  }

  .payment-interface .pay-container .pay-main-content p {
    text-align: center;
    font-size: 8px;
    line-height: 12px;
    margin: 15px 0 50px;
  }
}
