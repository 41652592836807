.footer {
    align-items: center;
    width: 100%;
    margin: 0;
    background: #EAFFEB;
    padding: 60px 120px;
}

.footer .footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
}

.footer .footer-logo {
    font-weight: 700;
    text-align: center;
}

.footer .footer-logo img {
    width: 50px;
    height: 60px;
}

.footer .footer-logo h1 {
    font-size: 14px;
}

.footer ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer ul li {
    margin-right: 40px;
}

.footer ul li a {
    font-size: 20px;
    color: #5A5A5A;
    line-height: 30px;
    font-weight: 500;
}

.copy-right {
    text-align: center;
    margin-top: 80px;
    font-size: 14px;
    color: #5A5A5A;
    font-weight: 300;
}

.copy-right p {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
}

@media screen and (max-width: 430px) {
    .footer {
        padding: 60px 15px;
    }

    .footer .footer-logo img {
        width: 30px;
        height: 35px;
    }

    .footer .footer-logo h1 {
        display: none;
    }

    .footer ul li {
        margin-right: 8px;
    }
    
    .footer ul li a {
        font-size: 12px;
        line-height: 18px;
    }

    .copy-right p {
        font-size: 12px;
        line-height: 18px;
    }

    .copy-right p img{
        width: 16px;
        height: 16px;
    }
}