.about-page {
    padding: 100px 120px;
}

.about-page .about-page-header {
    text-align: center;
    margin-bottom: 140px;
}

.about-page .about-page-header h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    color: var(--brand-color);
    margin-bottom: 12px;
}

.about-page .about-page-header p {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #5A5A5A;
}

.our-story {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    margin-bottom: 140px;
}

.our-story h3,
.our-services h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    color: var(--brand-color);
    margin-bottom: 12px;
}

.our-story p,
.our-services p,
.service-text ol li,
.about-page-action p {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #5A5A5A;
    margin-bottom: 12px;
}

.our-services .service-header {
    text-align: center;
    margin-bottom: 40px;
}

.our-services .service-img-text {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 100px;
}

.our-services .service-img-text .service-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 10px;
}

.about-page .about-page-action {
    text-align: center;
    margin-bottom: 100px;
}

.about-page .about-page-action button{
    display: flex;
    justify-content: center;
    margin: 0 auto;
}



@media screen and (max-width: 430px) {
    .about-page {
        padding: 0 15px; 
    }
    
    .about-page .about-page-header {
        text-align: center;
        margin: 60px 0;
    } 
    
    .about-page .about-page-header h1 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 12px;
    }
    
    .about-page .about-page-header p {
        font-size: 12px;
        line-height: 18px;
    }




    .our-story {
        flex-direction: column;
        gap: 50px;
        margin-bottom: 120px;
    }
    
    .our-story h3,
    .our-services h3 {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
    
    .our-story p,
    .our-services p,
    .service-text ol li,
    .about-page-action p {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 12px;
    }


    .story-img img {
        width: 300px;
        height: 240px;
    }

    .our-services .service-img-text {
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        margin-bottom: 100px;
    }

    .our-services .service-text ol li {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }

    .our-services .service-text {
        margin-top: 30px;
        width: 100%;
    }
    
    .our-services .service-img-text .service-images {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 10px;
    }

    .our-services .service-img-text .service-images img{
        width: 100%;
    }
    
    .about-page .about-page-action {
        text-align: center;
        margin-bottom: 100px;
    }
    
    .about-page .about-page-action button{
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 12px 32px;
        font-size: 10px;
        border-radius: 24px;
        line-height: 15px;
    }


}