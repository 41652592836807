.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .img {
    animation: slide-down 0.5s ease;
  }

  .form {
    width: 67rem;
    padding: 1.5rem;
    animation: slide-up 0.5s ease;
    background-color: #000;
    h2 {
      color: var(--color-danger);
      text-align: center;
    }

    form {

      
      // input[type="text"],
      // input[type="email"],
      // input[type="password"] {
      //   display: block;
      //   font-size: 1.6rem;
      //   font-weight: 300;
      //   padding: 1rem;
      //   margin: 1rem auto;
      //   width: 100%;
      //   border: 1px solid #ccc;
      //   border-bottom: 3px solid #ccc;
      //   border-radius: 3px;
      //   outline: none;

      //   &:focus {
      //     outline: none;
      //     box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      //     border-bottom: 3px solid #55c57a;
      //   }

      //   &:focus:invalid {
      //     border-bottom: 3px solid #ff7730;
      //   }
      // }
      .links {
        display: flex;
        justify-content: space-between;
        color: #ddd !important;
        margin: 5px 0;
      }

      p {
        text-align: center;
        margin: 1rem;
        color: #ddd;
      }

      
    }
    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ddd;
      margin-top: 1rem;

      p{
        color: blue !important;
        font-size: 12px;
      }
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 700px) {
  .img {
    display: none;
  }

  .auth{
    width: 100% !important;
  }

  .form{
    padding: 1.5rem 1rem;
    width: 100% !important;
  }
}
