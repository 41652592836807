.business-profile * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.business-profile {
    margin: 0 90px 0 30px;
    display: flex;
    /* gap: 5%; */
}

.business-profile .profile-navigation {
    width: 20%;
    /* background: #fff; */
}

.business-profile .profile-navigation ul li {
    margin-bottom: 22px;
}

.business-profile .profile-navigation ul li a {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #767676;
}

.business-profile .profile-navigation ul li a.active-profile-text {
    color: var(--brand-color);
}

.business-profile .business-profile-item {
    width: 80%;
}

.add-sales-rep {
    display: flex;
    /* align-items: center; */
    gap: 20px;
}

.add-sales-rep button {
    width: 100px;
    height: 30px;
    border: none;
    background: var(--brand-color);
    color: #fff;
    border-radius: 12px;

}

.business-profile .business-profile-item.stores {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid var(--brand-color);
}

.business-profile .business-profile-item.stores h3 {
    font-size: 32px;
    margin: 0;
}

.business-profile .business-profile-item>div {
    margin-bottom: 12px;
}

.business-profile .business-profile-item h3 {
    color: #5A5A5A;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 20px;
}

.business-profile .business-profile-item>div>h3 {
    /* background-color: red; */
    margin-left: 24px;
}

.business-profile .business-profile-item .item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    padding: 36px 24px;
    border: 1px solid var(--brand-color);
}

.business-profile .business-profile-item .item-container .item-img-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}



.item-container .item-img-name .sales-permissions {
    width: 150%;
}

.item-container .item-img-name .sales-permissions h3 {
    margin-bottom: 12px;
}

.item-container .item-img-name .sales-permissions tr {
    border-bottom: 1px solid #767676;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.item-img-name .sales-permissions tr td {
    padding: 7px 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    color: #767676;
}

.item-img-name .sales-permissions tr td:nth-child(1) {
    width: 70%;
}

.item-img-name .sales-permissions tr td:nth-child(2) {
    width: 20%;
}

.business-profile-item .item-container .item-img-name img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    /* border: 1px dashed var(--brand-color); */
}

.business-profile-item .item-container .item-img-name h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 2px;
}

.business-profile-item .item-container .item-img-name h5 {
    font-size: 16px;
    font-weight: 300;
    color: #767676;
}

.business-profile-item .item-container .item-action {
    padding: 8px 16px;
    border: 1px solid #8B8B8B;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    gap: 5px;
}

.business-profile-item .item-container .item-action label,
.item-action-container label {
    cursor: pointer;
}

.business-profile-item .item-container .item-action {
    display: flex;
    align-items: center;
}

.business-profile-item .item-container .item-action-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.item-action.upgrade {
    background: var(--brand-color);
    color: #fff;
}

.business-profile-item .item-container label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #8B8B8B;
}

.subscription-plan>div {
    margin-bottom: 12px;
}

.item-name.subscription-plan>div h3 {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    color: #767676;
}

.subscription-plan>div h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    color: var(--brand-color);
}

.subscription-plan>div h4 {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #767676;
}

.subscription-plan>div h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}





/* Adding new sales person */

.add-new-sales {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    /* max-height: 100vh; */
    overflow-y: auto;
    z-index: 12;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.add-new-sales .new-sales-data {
    background: #fff;
    padding: 40px;
    border-radius: 24px;
    margin-top: 60px;
    height: max-content;
}

.add-new-sales .new-sales-data .sales-details {
    width: 100%;
}

.new-sales-data .sales-details h1 {
    text-align: center;
    margin-bottom: 24px;
    color: var(--brand-color);
}

.add-new-sales .new-sales-data .sales-details .form-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.new-sales-data .sales-details .form-field>div {
    width: 48%;
}

.new-sales-data .sales-details .form-field>div input {
    width: 100%;
    background: transparent;
    border: 1px solid var(--brand-color);
    border-radius: 12px;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
}

.new-sales-data .sales-details .form-field>div label {
    margin-bottom: 4px;
    padding: 0 12px;
    font-size: 16px;
    color: var(--brand-color);
    line-height: 24px;
}

.form-field .password-input {
    position: relative;
    cursor: pointer;
}

.form-field .show-password {
    position: absolute;
    right: 30px;
    top: 55%;
}

.form-field .show-password .cross-line {
    width: 27px;
    height: 2px;
    background: var(--brand-color);
    position: absolute;
    top: 44%;
    right: -2px;
    transform: rotate(-40deg);
}

.new-sales-data .permissions h3 {
    margin-bottom: 12px;
}

.new-sales-data .permissions .permissions-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.permissions .permissions-boxes label {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--brand-color);
    gap: 16px;
}

.new-sales-data .submit-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.new-sales-data .submit-buttons button {
    width: 48%;
    border: 1px solid var(--brand-color);
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    background-color: transparent;
    border-radius: 12px;
}

.new-sales-data .submit-buttons button.add {
    background: var(--brand-color);
    color: #fff;
}



/* Edit business ------------------------------------------------ */
.edit-business-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    min-height: 80vh;
    z-index: 12;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}

.edit-business-modal .sign-up-businesss.edit {
    background: #fff;
    width: 80%;
    height: max-content;
    border-radius: 24px;
    padding: 40px;
    margin: 80px 0;
}

.edit-business-modal .sign-up-businesss.edit .sign-up-business-header h2 {
    text-align: center;
    margin-bottom: 12px;
    font-size: 28px;
    line-height: 32px;
    color: var(--brand-color);
}

.edit-business-modal .sign-up-businesss.edit .sign-up-actions h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 12px;
    color: var(--brand-color);
}

.edit-business-modal .sign-up-businesss.edit .edit-buttons {
    display: flex;
    gap: 25px;
    margin-top: 40px;
    cursor: pointer;
    width: 100%;
}

.edit-business-modal .sign-up-businesss.edit .edit-buttons button {
    width: 50%;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    border: none;
    color: #fff;
    background: var(--brand-color);
    border-radius: 12px;
}

.sign-up-businesss.edit .edit-buttons button.cancel {
    border: 1px solid var(--brand-color);
    background: transparent;
    color: var(--brand-color);
}

@media screen and (max-width: 430px) {
    .business-profile {
        margin: 0 18px;
        display: flex;
    }

    .business-profile {
        flex-direction: column;
        gap: 25px;
    }

    .business-profile .profile-navigation {
        width: 100%;
    }

    .business-profile .profile-navigation ul li {
        margin-bottom: 16px;
    }

    .business-profile .business-profile-item {
        width: 100%;
    }

    .business-profile .business-profile-item.stores {
        padding: 36px 0;
    }

    .business-profile .business-profile-item .item-container {
        border-radius: 20px;
        padding: 30px 12px;
    }

    .business-profile .business-profile-item .item-container .item-img-name {
        gap: 8px;
    }

    .business-profile-item .item-container .item-img-name img {
        height: 80px;
        width: 80px;
    }

    .business-profile-item .item-container .item-img-name h2 {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 2px;
    }

    .business-profile-item .item-container .item-img-name h5 {
        font-size: 10px;
        line-height: 15px;
    }

    .business-profile-item .item-container label {
        font-size: 8px;
        line-height: 15px;
    }

    .business-profile-item .item-container img {
        width: 10px;
        height: 10px;
    }


    .item-name.subscription-plan>div h3 {
        font-size: 10px;
        line-height: 15px;
    }
    
    .subscription-plan>div h1 {
        font-size: 20px;
        line-height: 30px;
    }
    
    .subscription-plan>div h4 {
        font-size: 10px;
        line-height: 15px;
    }
    
    .subscription-plan>div h6 {
        font-size: 12px;
        line-height: 18px;
    }

    .item-action.upgrade a {
        font-size: 10px;
        line-height: 15px;
    }

    .business-profile .business-profile-item.stores {

        align-items: center;
        border-radius: 24px;
        border: 1px solid var(--brand-color);
    }
    
    .business-profile .business-profile-item.stores h3 {
        font-size: 20px;
        line-height: 30px;
    }


    .business-profile-item .item-container .item-action-container {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .business-profile-item .item-container .item-action-container .item-action{
        padding: 6px 12px;
    }



    .item-container .item-img-name .sales-permissions {
        width: 130%;
    }
    
    .item-container .item-img-name .sales-permissions h3 {
        margin-bottom: 10px;
        line-height: 15px;
    }

    .item-container .item-img-name .sales-permissions td {
        margin-bottom: 10px;
        line-height: 15px;
    }

    .add-new-sales .new-sales-data {
        padding: 40px 20px;
        margin: 60px 10px;
        border-radius: 24px;
    }

    .add-new-sales .new-sales-data .sales-details .form-field {
        display: block;
        margin-bottom: 15px;
    }

    .new-sales-data .sales-details .form-field>div {
        width: 100%;
        margin-bottom: 15px;
    }










    /* Add New sales Modal */
    
    .add-new-sales .new-sales-data {
        padding: 40px 15px;
        border-radius: 12px;
    }
    
    .new-sales-data .sales-details h1 {
        margin-bottom: 18px;
        line-height: 28px;
    }
    
    .add-new-sales .new-sales-data .sales-details .form-field {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .new-sales-data .sales-details .form-field>div {
        width: 48%;
    }
    
    .new-sales-data .sales-details .form-field>div input {
        border-radius: 8px;
        font-size: 12px;
        padding: 9px;
        line-height: 21px;
    }
    
    .new-sales-data .sales-details .form-field>div label {
        padding: 0 8px;
        font-size: 12px;
        line-height: 21px;
    }
    
    .form-field .password-input {
        position: relative;
        cursor: pointer;
    }
    
    
    .permissions .permissions-boxes label {
        font-size: 10px;
        line-height: 21px;
    }
    
    .new-sales-data .submit-buttons button {
        font-size: 12px;
        padding: 9px;
        line-height: 21px;
        border-radius: 8px;
    }
    
    .new-sales-data .submit-buttons button.add {
        background: var(--brand-color);
        color: #fff;
    }








/* Edit Business */

.edit-business-modal .sign-up-businesss.edit {
    width: 95%;
    padding: 40px 15px;
}

.edit-business-modal .sign-up-businesss.edit .sign-up-business-header h2 {
    font-size: 24px;
    line-height: 32px;
}

.edit-business-modal .sign-up-businesss.edit .sign-up-actions h3 {
    font-size: 18px;
    margin: 12px 0;
}


.edit-business-modal .sign-up-businesss.edit .edit-buttons button {
    padding: 12px;
    font-size: 12px;
    line-height: 21px;
    border-radius: 8px;
}




}