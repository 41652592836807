.blog-section {
  padding: 40px 120px;
}

.blog-section.single-blog {
  padding: 40px 0;
}

.blog-section .search {
  display: flex;
  justify-content: end;
  width: fit-content;
  position: relative;
}

.blog-section.single-blog .search {
  display: flex;
  justify-content: end;
  margin: 0 120px;
  width: fit-content;
  position: relative;
}

.blog-section .search-section {
  display: flex;
  justify-content: end;
}

.blog-section .search input {
  width: 320px;
  border-radius: 30px;
  border: 1px solid var(--brand-color);
  padding: 10px 60px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  outline: none;
}

.blog-section .search button {
  position: absolute;
  left: 12px;
  top: 4px;
  height: 40px;
  width: 40px;
  background: transparent;
  border-radius: 50%;
  color: var(--brand-color);
  border: none;
  cursor: pointer;
}

.blog-section .blog-section-head {
  padding: 70px;
}

.blog-section .blog-section-head h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--brand-color);
}

.blog-section .blog-section-head p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  text-align: center;
  color: #5a5a5a;
}

/* .blog-section .blog-section-body {
    background-color: #5A5A5A;
} */

.blog-section .blog-section-body .blog-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  margin-bottom: 100px;
}

.blog-section.single-blog .blog-section-body .blog-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  margin-bottom: 100px;
}

.blog-section.single-blog .blog-section-body {
  margin-top: 80px;
}

.blog-section .blog-section-body .blog-cards .blog-card {
  border: 1px solid var(--brand-color);
  border-radius: 24px;
  padding: 12px 14px;
}

.blog-section.single-blog .blog-section-body .blog-cards .blog-card {
  border: none;
  border-radius: 0;
  padding: 0;
}

.blog-section .blog-section-body .blog-cards .blog-card .blog-card-img {
  margin-bottom: 30px;
  min-height: 344px;
  width: 100%;
}

.blog-section.single-blog .blog-section-body .blog-cards .blog-card .blog-card-img {
  margin-bottom: 90px;
  max-height: min-content;
  width: 100%;
}

.blog-section .blog-section-body .blog-cards .blog-card .blog-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-section .blog-section-body .blog-cards .blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  color: var(--brand-color);
  text-align: left;
}

.blog-section.single-blog .blog-section-body .blog-cards .blog-card h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: justify;
  color: var(--brand-color);
  margin-bottom: 12px;
}

.blog-section.single-blog .blog-section-body .blog-cards .blog-card .blog-card-body {
  padding: 0 120px;
}

.blog-section .blog-section-body .blog-cards .blog-card p {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  margin-bottom: 30px;
  text-align: left;
  color: #5a5a5a;
}

.blog-section.single-blog .blog-section-body .blog-cards .blog-card p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: justify;
  color: #5a5a5a;
  margin-bottom: 30px;
}

.blog-section .blog-section-body .blog-cards .blog-card button {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: var(--brand-color);
  border: none;
  color: #fff;
  padding: 10px 0;
  border-radius: 20px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 430px) {
  .blog-section {
    padding: 40px 15px;
  }

  .blog-section .search input {
    width: 220px;
    border-radius: 24px;
    padding: 10px 45px;
  }

  .blog-section .blog-section-head {
    padding: 48px 0;
  }

  .blog-section .blog-section-head h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .blog-section .blog-section-head p {
    font-size: 12px;
    line-height: 18px;
  }

  .blog-section .blog-section-body .blog-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 30px;
  }

  .blog-section .blog-section-body .blog-cards .blog-card {
    border: none;
    border-radius: 24px;
    padding: 0;
  }

  .blog-section .blog-section-body .blog-cards .blog-card h3 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .blog-section .blog-section-body .blog-cards .blog-card p {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 30px;
    text-align: center;
  }

  .blog-section .blog-section-body .blog-cards .blog-card button {
    font-size: 10px;
    line-height: 15px;
    padding: 12px 42px;
    border-radius: 24px;
  }







  .blog-section.single-blog .search {
    margin: 0 15px;
  }

  .blog-section.single-blog .blog-section-body .blog-cards .blog-card .blog-card-body {
    padding: 0 15px;
  }


  .blog-section.single-blog .blog-section-body .blog-cards .blog-card h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .blog-section.single-blog .blog-section-body .blog-cards .blog-card p {
    font-size: 12px;
    line-height: 18px;
  }

  .blog-section.single-blog .blog-section-body .blog-cards .blog-card .blog-card-img {
    margin-bottom: 40px;
  }

  .blog-section .blog-section-body .blog-cards .blog-card .blog-card-img {
    margin-bottom: 30px;
    min-height: unset;
  }

}
