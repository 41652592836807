.sign-up-businesss {
  /* box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1); */
  margin: 80px 200px;
}

.sign-up-businesss .sign-up-business-header {
  margin-bottom: 120px;
  text-align: center;
}

.sign-up-businesss .sign-up-business-header h1 {
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: var(--brand-color);
}

.sign-up-businesss .sign-up-business-header p a {
  color: #da20ff;
}

.sign-up-businesss .sign-up-cards {
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.sign-up-businesss .sign-up-cards .sign-up-lines {
  width: 160px;
  height: 0;
  border: 1px dashed var(--brand-color);
}

.sign-up-businesss .sign-up-cards .sign-up-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-up-businesss .sign-up-cards .sign-up-card span {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: var(--brand-color);
}

.sign-up-businesss .sign-up-cards .sign-up-card .sign-up-card-icon {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: var(--brand-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-businesss .sign-up-cards .sign-up-card .sign-up-card-icon.active-icon-card {
  background: transparent;
  border: 1px solid var(--brand-color);
}

.sign-up-businesss .sign-up-cards .sign-up-card .sign-up-card-icon.active-icon-card img {
  background: var(--brand-color);
  border-radius: 24px;
}

.sign-up-businesss .company-logo {
  display: flex;
  justify-content: center;
}

.sign-up-businesss .company-logo .company-logo-icon {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #f3fff4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sign-up-businesss .company-logo .company-logo-icon>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.sign-up-businesss .company-logo .company-logo-icon .company-logo-icon-pencil {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 98px;
  left: 127px;
  border-radius: 48px;
  background: var(--brand-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;
}

/* .sign-up-businesss .sign-up-actions {
  padding: 0 200px;
} */

.sign-up-businesss .sign-up-form-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;
}

.sign-up-form-field .password-input {
  position: relative;
  cursor: pointer;
}

.sign-up-form-field .password-input .show-password {
  position: absolute;
  right: 30px;
  top: 60%;
}

.sign-up-form-field .password-input .show-password .cross-line {
  width: 27px;
  height: 2px;
  background: var(--brand-color);
  position: absolute;
  top: 44%;
  right: -2px;
  transform: rotate(-40deg);
}

.sign-up-businesss .sign-up-form-field>div {
  width: 48%;
}

.sign-up-businesss .sign-up-form-field>div input {
  width: 100%;
  color: var(--brand-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 16px;
  border: 1px solid var(--brand-color);
  border-radius: 12px;
  outline: none;
}

.sign-up-businesss .sign-up-form-field>div input::placeholder {
  color: #b1b0b1;
  opacity: .4;
}

.sign-up-businesss .sign-up-form-field>div label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 0 16px;
  color: var(--brand-color);
  margin-bottom: 12px;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons {
  margin-top: 68px;
  display: flex;
  justify-content: space-between;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button {
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border: none;
  background: transparent;
  border-radius: 12px;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button.go-back {
  padding-left: 0;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button.proceed {
  background: var(--brand-color);
  color: #fff;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button.proceed.disabled_bg {
  background: #cdcdcd;
  color: #fff;
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button.go-back img {
  padding-right: 32px;
  color: var(--brand-color);
}

.sign-up-businesss .sign-up-actions .sign-up-buttons button.proceed img {
  padding: 0 32px;
}

.sign-up-businesss .sign-up-actions .verify-informations {
  display: flex;
  justify-content: center;
  padding: 20px 200px 0;
}

.sign-up-businesss .sign-up-actions .verify-informations h3 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 40px;
}

.sign-up-businesss .sign-up-actions .verify-informations .agreement_checkbox {
  display: flex;
  gap: 10px;
}

.sign-up-businesss .sign-up-actions .verify-informations .agreement_checkbox p a{
  color: #da20ff;
}

.sign-up-businesss .sign-up-actions .verify-informations input {
  height: 20px;
  width: 20px;
  background-color: #eee;
}


@media screen and (max-width: 430px) {
  .sign-up-businesss {
    margin: 70px 26px;
  }

  .sign-up-businesss .sign-up-business-header {
    margin-bottom: 30px;
  }

  .sign-up-businesss .sign-up-business-header h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  .sign-up-businesss .sign-up-cards {
    padding: 0;
    display: flex;
  }

  .sign-up-businesss .sign-up-cards .sign-up-card .sign-up-card-icon {
    width: 40px;
    height: 40px;
  }

  .sign-up-businesss .sign-up-cards .sign-up-card .sign-up-card-icon img {
    width: 16px;
    height: 16px;
  }

  .sign-up-businesss .sign-up-actions .verify-informations {
    display: flex;
    justify-content: center;
    padding: 20px 0 0;
  }

  .sign-up-businesss .company-logo .company-logo-icon {
    width: 80px;
    height: 80px;
  }

  .sign-up-businesss .sign-up-form-field {
    width: 100%;
    flex-direction: column;
    gap: 28px;
  }

  .sign-up-businesss .sign-up-form-field>div {
    width: 100%;
  }

  .sign-up-businesss .sign-up-form-field>div label {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .sign-up-businesss .sign-up-form-field>div input {
    font-size: 12px;
    padding: 15px;
  }

  .sign-up-businesss .sign-up-actions .sign-up-buttons button {
    font-size: 12px;
  }

  .sign-up-businesss .sign-up-actions .sign-up-buttons button.go-back img {
    padding-right: 12px;
  }
  
  .sign-up-businesss .sign-up-actions .sign-up-buttons button.proceed img {
    padding: 0 12px;
  }

  .sign-up-businesss .sign-up-actions .sign-up-buttons button {
    padding: 16px;
  }

  .sign-up-businesss .company-logo .company-logo-icon .company-logo-icon-pencil {
    width: 24px;
    height: 24px;
    top: 45px;
    left: 60px;
    margin-bottom: 28px;
  }

  .sign-up-businesss .company-logo .company-logo-icon .company-logo-icon-pencil img{
    width: 10px;
    height: 10px;
  }

  .sign-up-businesss .sign-up-cards .sign-up-lines {
    width: 100px;
    margin-bottom: 15px;
  }

  .sign-up-businesss .business-informations > h6 {
    font-size: 8px;
  }

}