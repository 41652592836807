.add-product {
  margin-bottom: 5rem;
  .card {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
  form {
    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: var(--color-dark);
    }
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"],
    select,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }
}

.group {
  border: 1px solid var(--dark-blue);
  padding: 5px;
}

// .image-preview {
//   width: 100%;
//   height: 100%;
//   max-height: 280px;
//   background: var(--dark-blue);
//   padding: 1rem;
//   border-radius: 3px;
//   overflow: hidden;
//   // display: none;
//   transition: all 0.3s;
// }

.image-preview img {
  width: 100%;
}

.image-show {
  display: block;
}

// .image-upload-section {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .image-upload-section input {
//   margin-right: 20px;
// }

// .image-preview {
//   // max-width: 150px;
// }

.image-preview img {
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.image-upload-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.image-upload-container {
  width: 500px;
  height: 200px;
  border: 1px solid var(--brand-color);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  // background-color: #f9f9f9;
  text-align: center;
  color: #555;
}

.image-upload-container:hover {
  background-color: #eaeaea;
}

.placeholder-text {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: rgba(142, 156, 195, 1);
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  border-radius: 12px;
}
