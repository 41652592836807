.contact-us-page {
  padding: 100px 120px;
}

.contact-us-page .contact-us-header {
  text-align: center;
  margin-bottom: 14px;
}

.contact-us-page .contact-us-header h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 12px;
  color: var(--brand-color);
}

.contact-us-page .contact-us-header p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #5A5A5A;
}

.contact-us-page .conatct-us-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 100px 0;
}

.contact-us-page .conatct-us-cards .contact-us-card {
  text-align: center;
}

.contact-us-page .conatct-us-cards .contact-us-card img {
  margin-bottom: 30px;
}

.contact-us-page .conatct-us-cards .contact-us-card h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.contact-us-page .contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 100px;
  margin-bottom: 100px;
}

.contact-us-page .contact-form .contact-form-text h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  color: var(--brand-color);
  margin-bottom: 16px;
}

.contact-us-page .contact-form .contact-form-text p {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #5A5A5A;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group .input-field {
  width: 47%;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group .input-field label {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--brand-color);
  margin-bottom: 12px;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group .input-field.message {
  width: 100%;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group input {
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid var(--brand-color);
  font-size: 16px;
  border-radius: 12px;
  outline: none;
}

.contact-us-page .contact-form .contact-form-inputs form .input-group textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid var(--brand-color);
  font-size: 16px;
  border-radius: 12px;
  outline: none;
}

.contact-us-page .contact-form .contact-form-inputs form button {
  width: 100%;
}


@media screen and (max-width: 430px) {
  .contact-us-page {
    padding: 40px 15px;
  }

  .contact-us-page .contact-us-header h1 {
    font-size: 24px;
    line-height: 36px;
  }

  .contact-us-page .contact-us-header p {
    font-size: 12px;
    line-height: 18px;
  }

  .contact-us-page .conatct-us-cards {
    grid-template-columns: 1fr;
    margin: 120px 0;
    gap: 40px;
  }

  .contact-us-page .conatct-us-cards .contact-us-card {
    padding: 0 47px;
  }

  .contact-us-page .conatct-us-cards .contact-us-card img {
    margin-bottom: 20px;
    width: 20px;
    height: 16px;
  }

  .contact-us-page .conatct-us-cards .contact-us-card h3 {
    font-size: 12px;
    line-height: 18px;
  }





  .contact-us-page .contact-form {
    grid-template-columns: 1fr;
    align-items: center;
    gap: 60px;
    margin-bottom: 60px;
  }

  .contact-us-page .contact-form .contact-form-text h3 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 16px;
  }
  
  .contact-us-page .contact-form .contact-form-text p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .contact-us-page .contact-form .contact-form-inputs form .input-group {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .contact-us-page .contact-form .contact-form-inputs form .input-group .input-field {
    width: 100%;
  }

  .contact-us-page .contact-form .contact-form-inputs form .input-group .input-field label {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 12px;
  }

  .contact-us-page .contact-form .contact-form-inputs form button {
    font-size: 10px;
    line-height: 15px;
  }
  



  .contact-us-page .contact-form .contact-form-inputs form .input-group input {
    font-size: 12px;
    line-height: 18px;
  }
  
  .contact-us-page .contact-form .contact-form-inputs form .input-group textarea {
    padding: 12px;
    font-size: 12px;
    line-height: 18px;
    height: 150px;
  }

}