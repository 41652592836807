.customers-list .defaul-inventory-header .mobile_send_icon,
.mobile_send_button {
  display: none;
}

.customers-list .defaul-inventory-header .mobile_send_text {
  display: block;
}

.customers-list table tr input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--brand-color);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--brand-color);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.customers-list table tr input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--brand-color);
}

.customers-list table tr input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.contact-search {
  display: flex;
  gap: 20px;
  align-items: center;
}

.contact-search button {
  /* height: 34px;
    width: 120px; */
  padding: 8px 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border: none;
  background: var(--brand-color);
  color: #fff;
  border-radius: 12px;
}

.customers-message-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.customers-message-container .message-body {
  padding: 85px 100px 32px;
  justify-content: center;
  border-radius: 20px;
}

.customers-message-container .message-body h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 40px;
  text-align: center;
  color: var(--brand-color);
}

.customers-message-container .message-body .submit-btn {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

/* .cancel-modal {
  position: absolute;
  top: 140px;
  right: 320px;
} */

.customers-message-container .message-body button {
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background: var(--brand-color);
}

.customers-message-container .message-body .customers-message-form {
  width: 800px;
}

.customers-message-form .form-field {
  width: 100%;
  display: flex;
  gap: 5%;
  margin-bottom: 20px;
}

.customers-message-form .form-field label {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: right;
  width: 20%;
  color: var(--brand-color);
}

.customers-message-form .form-field input,
.customers-message-form .form-field textarea {
  border-radius: 12px;
  outline: none;
  font-size: 16px;
  padding: 12px 18px;
  line-height: 24px;
  width: 75%;
  border: 1px solid var(--brand-color);
}

/* Overlay that dims the background */
.message-type-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Centered dialog box */
.message-type-dialog {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1100;
  text-align: center;
}

.message-type-dialog .cancel_message_dialogue {
  position: relative;
  left: 47%;
  cursor: pointer;
  top: -6px;
}

/* Heading styles */
.message-type-dialog h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

/* Buttons for selecting message type */
.message-type-options button {
  margin: 10px 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--brand-color);
  color: #fff;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.message-type-options button:hover {
  background-color: #333;
}

/* Cancel button (icon in the corner) */
.cancel-modal {
  position: relative;
  left: 62%;
  cursor: pointer;
  top: -240px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
}

.cancel-modal:hover {
  color: #000;
}

@media screen and (max-width: 430px) {
  .customers-list .defaul-inventory-header {
    display: flex;
    flex-direction: row;
  }

  .customers-list .defaul-inventory-header .mobile_send_icon,
  .mobile_send_button {
    display: block;
  }

  .mobile_send_button {
    padding: 8px;
    border: none;
    background: var(--brand-color);
    color: #fff;
    border-radius: 6px;
    display: flex;
    margin: 10px 15px;
    float: right;
  }

  .customers-list .defaul-inventory-header .mobile_send_text {
    display: none;
  }

  .contact-search button {
    display: none;
  }

  .customers-message-container .message-body {
    width: 100%;
    padding: 85px 0 32px;
    border-radius: 20px;
    /* position: relative; */
  }

  .customers-message-container .message-body > h1 {
    font-size: 16px;
    line-height: 24px;
  }

  .cancel-modal {
    position: relative;
    top: 100px;
    right: -115px;
  }

  .customers-message-container .message-body button {
    font-size: 10px;
    line-height: 15px;
  }

  .customers-message-container .message-body .customers-message-form {
    width: 95%;
  }

  .customers-message-form .form-field label {
    font-size: 12px;
    line-height: 18px;
    width: 30%;
  }

  .customers-message-form .form-field input,
  .customers-message-form .form-field textarea {
    border-radius: 6px;
    font-size: 12px;
    line-height: 21px;
    padding: 12px 16px;
    width: 70%;
  }
}
