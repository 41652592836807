.search {
  // margin: 5px 0;
  position: relative;
  background: transparent !important;
  width: 300px;
  flex: 1;

  .icon {
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
  }

  input[type="text"] {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--brand-color);
    border-radius: 7px;
    outline: none;
    font-size: 12px;
    padding: 6px 0 6px 49px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 430px) {
  .search {
    // margin: 5px 0;
    position: relative;
    background: transparent !important;
    width: 100%;
    flex: 1;

    .icon {
      position: absolute;
      top: 50%;
      left: 2rem;
      width: 9px ;
      height: 9px;
      transform: translateY(-50%);
    }

    input[type="text"] {
      display: block;
      width: 100%;
      font-size: 9px;
      padding: 5px 0 5px 29px;
      line-height: 9px;
    }
  }
}
