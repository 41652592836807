.statistics-container {
  display: flex;
  align-items: center;
}

.statistics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 2rem 1rem 2rem 0;
  gap: 15px;
  width: 100%;
  position: relative;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.statistics::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.scroll-button img {
  background-color: none;
}

.scroll-button.left {
  position: absolute;
  top: 30px;
  left: 300px;
}

.scroll-button.right {
  right: 5px;
}

.statistics .query-container {
  cursor: pointer;
  position: sticky;
  background: #f9f9f9;
  width: 300px;
  left: 0;
}

.statistics .query-container .query-cells {
  display: none;
  box-shadow: 2px 2px 10px #ddd;
  transition: all 1s ease;
}

.query-container:has(.show-query) { 
  position: relative;
} 

.statistics .query-container .query-cells.show-query {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  align-items: center;
  justify-content: center;
}

.show_query_container {
  background-color: #f9f9f9;
  margin: 0 10px;
  border-radius: 10px;
  padding: 15px;
  max-width: 400px;
  height: max-content;
}

.statistics .query-container .query-cells .dates_selection {
  display: flex;
  gap: 14px;
}

.dates_selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.from,
.to {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.from input.date_picker_input,
.to input.date_picker_input {
  width: 100%;
  padding: 0.5rem; 
  box-sizing: border-box;
}

.statistics .query-container .query-cells button {
  padding: 8px 15px;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  background: var(--brand-color);
  margin: 12px 0;
  width: 100%;
}

.statistics .query-container .query-cells .specific_selection h1 {
  color: rgba(90, 90, 90, 1);
  text-align: center;
  color: var(--brand-color);
  border-radius: 5px;
  border: 1px solid rgba(90, 90, 90, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 8px 0;
  margin-bottom: 8px;
}

.statistics .query-container .query-cells .specific_selection h1.selected_date {
  background-color: var(--brand-color);
  color: #fff;
}

.query-cells .dash {
  width: 7px;
  height: 2px;
  background-color: var(--brand-color);
  margin-top: 25px;
}

.statistics .query-container .query-cells label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 0 0 8px 12px;
}

.statistics .query-container .query-cells input {
  border: 1px solid var(--brand-color);
  outline: none;
  border-radius: 6px;
  padding: 7px 12px 7px 29px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #5a5a5a;
}

.statistics .query-container .query-cells .from > div,
.statistics .query-container .query-cells .to > div {
  position: relative;
}

.statistics .query-container .query-cells .from > div img,
.statistics .query-container .query-cells .to > div img {
  position: absolute;
  top: 30%;
  left: 13px;
}

.salesList-table tr td,
.salesList-table tr th,
.product-group-list tr td,
.product-group-list tr th {
  text-align: center;
}

.sales-page .statistics div.sales-informations {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 80px;
  border-radius: 10px;
  background: var(--brand-color);
}

.sales-page .statistics div.sales-informations div {
  background: var(--brand-color);
}

.sales-page .statistics div.sales-informations div > span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.sales-page .statistics div.sales-informations > div h1 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 0;
  line-height: 24px;
}

.sales-page .query {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 32px;
  background: transparent;
  border: 1px solid var(--brand-color);
  border-radius: 10px;
}

.sales-page .query span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background: none;
  color: var(--brand-color);
}

.sales-page .query img {
  background: none;
}

.t-sales h1,
.t-profit h1 {
  font-size: 14px;
  color: black;
  padding: 15px 0;
  border-radius: 5px;
}

@media screen and (max-width: 430px) {
  .statistics {
    margin: 12px 0;
    gap: 16px;
  }

  .statistics .query-container {
    width: 140px;
  }

  .query-cells .dash {
    margin-top: 25px;
  }

  .show_query_container {
    margin: 0;
    border-radius: 10px;
    padding: 15px;
    width: 350px;
    height: max-content;
  }

  .statistics .query-container .query-cells input {
    padding: 8px 10px;
  }

  .scroll-button.left {
    display: none;
  }

  .t-sales h1,
  .t-profit h1 {
    font-size: 10px;
  }

  .t-sales span,
  .t-profit span {
    font-size: 12px;
  }

  .sales-page .query {
    padding: 14px;
    border-radius: 4px;
    gap: 4px;
  }

  .sales-page .query span {
    font-size: 8px;
    font-weight: 600;
    width: 50px;
    line-height: 12px;
  }

  .sales-page .query img {
    background: none;
    width: 12px;
    height: 12px;
  }

  .query select {
    padding: 8px 3px;
    font-size: 13px;
  }

  .sales-page .statistics div.sales-informations {
    width: 100%;
    gap: 10px;
  }

  .sales-page .statistics div.sales-informations {
    padding: 0 10px;
    border-radius: 4px;
    justify-content: center;
  }

  .sales-page .statistics div.sales-informations > div span {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
  }

  .sales-page .statistics div.sales-informations > div h1 {
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;
  }
}
