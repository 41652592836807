* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 5px 30px 0px #00000008;
  /* position: fixed; */
  z-index: 100;
  width: 100%;
  padding: 0 120px;
}

.header .logo {
  font-weight: 700;
  text-align: center;
}

.header .logo img {
  width: 50px;
  height: 60px;
}

.header .logo h1 {
  font-size: 14px;
  color: #295f2d;
  line-height: 21px;
}

.header .home-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .home-links > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .home-links .links > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .home-links div li {
  margin-right: 40px;
}

.header .home-links div li a {
  font-size: 20px;
  color: #5a5a5a;
  line-height: 30px;
  font-weight: 300;
}

.header .home-links div li a.active {
  color: #295f2d;
  font-weight: 600;
}

.header .home_mobile_icon,
.header .home-links .cancel_mobile_menu, .links-1 .logo, .nav-link svg {
  display: none;
}


@media screen and (max-width: 430px) {
  .header {
    display: flex;
    /* position: fixed; */
    z-index: 100;
    padding: 0 40px;
  }

  .header .logo img {
    width: 30px;
    height: 35px;
  }

  .header .logo h1 {
    font-size: 8px;
    line-height: 12px;
  }

  .header .home_mobile_icon,
  .header .home-links .cancel_mobile_menu {
    display: block;
  }

  .header .cancel_mobile_menu img {
    position: absolute;
    top: 50px;
  }

  .header .cancel_mobile_menu {
    display: flex;
    justify-content: start;
  }

  .header .home-links {
    display: none;
  }

  .header .home-links.show_mobile_menu {
    display: flex;
    position: fixed;
    backdrop-filter: blur(5px);
    align-items: stretch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .header .home-links .links > div {
    align-items: stretch;
  }

  .header .home-links.show_mobile_menu .links {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    box-shadow: 4px 0px 40px 0px #0000000D;
  }

  .header .home-links.show_mobile_menu .links .links-1 {
    flex-direction: column;
    width: 240px;
    margin: 40px 16px;
  }

  .header .home-links.show_mobile_menu .links .links-1 .logo{
    display: block;
    margin-bottom: 40px;
  }

  .header .home-links.show_mobile_menu .links .links-1 .logo img{
    width: 40px;
    height: 46px;
  }

  .header .home-links.show_mobile_menu .links .links-1 .logo h1{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

  .header .home-links div li {
    padding: 15px 12px;
    display: flex;
    margin: 0;
    gap: 12px;
    border-radius: 12px;
  }

  li:has(.active) {
    background-color: var(--brand-color);
  }

  .nav-link svg {
    display: inline-block;
    margin-right: 12px;
  }

  .header .home-links div li a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    /* padding: 15px 6px; */
  }

  .header .home-links div li a.active {
    color: #f9f9f9;
    font-weight: 600;
  }

  .header .home-links.show_mobile_menu .links .links-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
    padding: 16px;
  }

  .header .home-links.show_mobile_menu .links .links-2 .--btn-primary{
    width: 100%;
  }


}
