.fulfilment_page {
  color: #333;
  padding: 5px;
}

.fulfilment_page .table {
  width: 100%;
  overflow-x: auto;
}

.fulfilment_page .table table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1.4rem;
  min-width: 100%;
  overflow-x: auto;
}

.fulfilment_page .table .search {
  width: 100%;
  max-width: 300px;
}

.fulfilment_page .table table th {
  padding: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  background-color: #fff;
  color: var(--brand-color);
  border: none;
}

.fulfilment_page .table table td {
  padding: 16px 0 16px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #5a5a5a;
}

.fulfilment_page .table table th,
.fulfilment_page .table table td {
  vertical-align: middle;
  text-align: center;
}

.fulfilment_page .table table th.icons,
.fulfilment_page .table table td.icons {
    padding: 0;
    margin: auto;
}

.fulfilment_page .table table th.icons > div,
.fulfilment_page .table table td.icons > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fulfilment_page .table table th.icons > *,
.fulfilment_page .table table td.icons > * {
  margin-right: 7px;
  cursor: pointer;
}

.fulfilment_page .table table tr {
  border-bottom: none;
}

.fulfilment_page .table table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.fulfilment_page .table table tbody {
  border-bottom: 0;
}

.fulfilment_page .table table tbody tr:hover {
  cursor: pointer;
}

.fulfilment_page .table table tbody .sell-action {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.fulfilment_page .table table tbody .td-sell-btn {
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 500;
  color: var(--brand-color);
  line-height: 24px;
  letter-spacing: 0em;
}

.fulfilment_page .dynamic-content {
  padding: 10px;
  /* width: 100%; */
  background-color: #f9f9f9;
  border-radius: 12px;
}

.fulfilment_page .dynamically-display-header {
  padding: 10px;
  display: grid;
  color: var(--brand-color);
  margin-top: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

.fulfilment_page .dynamically-display-body {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.fulfilment_page .payments_header {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 0 0;
  background: var(--brand-color);
  color: #fff;
  border-radius: 5px;
}

.fulfilment_page .payment_parts {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.fulfilment_page .center {
  text-align: center;
}

.fulfilment_page .right {
  text-align: right;
}

.fulfilment_page .icons span.fufilment_field {
  /* background-color: #333; */
  display: flex;
  gap: 10px;
}

.fulfilment_page .icons span.fufilment_field input {
  background: none;
  outline: none;
  border: 1px solid var(--brand-color);
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
}

.fulfilment_page .icons span.fufilment_field button {
  border: 1px solid var(--brand-color);
  background-color: var(--brand-color);
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  padding: 10px;
}

@media screen and (max-width: 430px) {
  .fulfilment_page .table {
    padding: 5px;
  }

  .fulfilment_page .table table {
    border-collapse: collapse;
    width: 100%;
    min-width: 100%;
    overflow-x: auto;
    display: block;
  }

  .fulfilment_page .table table th {
    padding: 4px 12px;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    border: none;
  }

  .fulfilment_page .table table td {
    padding: 4px 10px;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
  }

  .fulfilment_page .table table th,
  .fulfilment_page .table table td {
    text-align: center;
    border: none;
  }

  .fulfilment_page .table table th.icons > *,
  .fulfilment_page .table table td.icons > * {
    margin-right: 7px;
    cursor: pointer;
  }

  .fulfilment_page .table table th.icons span img,
  .fulfilment_page .table table td.icons span img {
    width: 11px;
    height: 11px;
  }

  .fulfilment_page .table table tr {
    border-bottom: none;
  }

  .fulfilment_page .table table tr:nth-child(odd) {
    background-color: #f9f9f9;
  }

  .fulfilment_page .table table tbody {
    border-bottom: 0;
  }

  .fulfilment_page .table table tbody tr:hover {
    cursor: pointer;
  }

  .fulfilment_page .table table tbody .sell-action {
    font-size: 6px;
    line-height: 9px;
  }

  .fulfilment_page .table table tbody .td-sell-btn {
    font-size: 6px;
    line-height: 9px;
  }

  .actions span img {
    width: 8px;
    height: 8px;
  }

  .fulfilment_page .dynamic-content {
    padding: 5px;
    margin: 5px 0;
  }

  .fulfilment_page .dynamically-display-header {
    padding: 2px;
    font-size: 10px;
  }

  .fulfilment_page .dynamically-display-body {
    padding: 2px;
    font-size: 10px;
  }

  .fulfilment_page .icons span.fufilment_field {
    gap: 5px;
  }

  .fulfilment_page .icons span.fufilment_field input {
    border-radius: 6px;
    font-size: 8px;
    padding: 6px;
  }

  .fulfilment_page .icons span.fufilment_field button {
    border-radius: 6px;
    font-size: 8px;
    padding: 6px;
  }
}
